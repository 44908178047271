import { Link } from 'react-router-dom';
import { BiTimeFive } from 'react-icons/bi';
import { AiOutlineCalendar } from 'react-icons/ai';
import './CourseCard.css';

function CourseCard({ id, image, area, title, duration, conclusion }) {

    return (
        <div className="course-card">
            <img src={image} alt="" />
            <div className='infos'>
                <p className='area'>{area}</p>
                <h3>{title}</h3>
                <p className='duration'>
                    <BiTimeFive className='icon' />
                    Duração: {duration}
                </p>
                <p className='conclusion'>
                    <AiOutlineCalendar className='icon' />
                    Conclusão: {conclusion}
                </p>
                <Link to={`/course/${id}`} ><button>Saiba mais</button></Link>
            </div>
        </div>
    )
}

export default CourseCard;