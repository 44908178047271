import {useState} from 'react';
import CaseModal from '../CaseModal';
import './CaseCard.css';

function CaseCard(props) {
    const {images, title, summary} = props;
    const [open, setOpen] = useState(false);
    const parsedSummary = `${summary.slice(0, 150)}...`;
    
    return (
        <div className="case-card">
            <img src={images[0]} alt="imagem do projeto" />
            <h3>{title}</h3>
            <p>{parsedSummary}</p>
            <button onClick={() => setOpen(true)} >Saiba mais</button>
            {open && <CaseModal {...props} open={open} setOpen={setOpen} /> }
        </div>
    )
}

export default CaseCard;