import React, { useState } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import './styles.css';


function ArrowToTop() {
    const [showScroll, setShowScroll] = useState(false);

    const scrollTop = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth'});
    };

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 150) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 150) {
            setShowScroll(false)
        }
    };

    window.addEventListener('scroll', checkScrollTop)
    return (
        <FaArrowUp
            className="scrollTop"
            onClick={scrollTop}
            style={{ display: showScroll ? 'flex' : 'none' }}
            size={60}
            fill={'#FFF'}
        />
    );
}

export default ArrowToTop;