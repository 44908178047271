import make_music_01 from '../assets/eventsimages/makemusic/1.jpg';
import make_music_02 from '../assets/eventsimages/makemusic/2.jpg';
import make_music_03 from '../assets/eventsimages/makemusic/3.jpg';
import make_music_04 from '../assets/eventsimages/makemusic/4.jpg';
import make_music_05 from '../assets/eventsimages/makemusic/5.jpg';
import make_music_06 from '../assets/eventsimages/makemusic/6.jpg';
import make_music_07 from '../assets/eventsimages/makemusic/7.jpg';
import make_music_08 from '../assets/eventsimages/makemusic/8.jpg';
import make_music_09 from '../assets/eventsimages/makemusic/9.jpg';
import make_music_10 from '../assets/eventsimages/makemusic/10.jpg';
import make_music_11 from '../assets/eventsimages/makemusic/11.jpg';
import make_music_12 from '../assets/eventsimages/makemusic/12.jpg';
import make_music_13 from '../assets/eventsimages/makemusic/13.jpg';
import make_music_14 from '../assets/eventsimages/makemusic/14.jpg';
import make_music_15 from '../assets/eventsimages/makemusic/15.jpg';
import make_music_16 from '../assets/eventsimages/makemusic/16.jpg';
import make_music_17 from '../assets/eventsimages/makemusic/17.jpg';
import make_music_18 from '../assets/eventsimages/makemusic/18.jpg';
import make_music_h1 from '../assets/eventsimages/makemusic/h3.jpg';
import make_music_h2 from '../assets/eventsimages/makemusic/h6.jpg';
import logo_pmfi from '../assets/outrasEmpresas/PMFI_Logo.png';


const events = [
    {
        id: 'makemusic',
        title: 'Make Music Brasil - Foz do Iguaçu',
        summary: "O projeto foi desenvolvido pela Associada Profissional, Bárbara Françoise Cardoso Bauermann e teve como principal objetivo, realizar o levantamento de dados socioeconômicos da região dos municípios lindeiros ao Lago de Itaipu, para subsidiar projetos de desenvolvimento regional. Entre os ",
        content: [
            { type: 'text', text: 'O Make Music Brasil iniciou-se nas Cataratas do Iguaçu (PNI) as 10h da manhã do dia 21 de Junho de 2024, com apresentação de Raphael Thiago e Mariele Lopes, encerrando-se as 11h.' },
            { type: 'image', src: [make_music_01, make_music_02], alt: 'Fotos: Marcos Labanca' },
            { type: 'text', text: ' ' },
            { type: 'text', text: 'No momento do show assistiram cerca de 300 pessoas entre turistas e população local.' },
            { type: 'text', text: 'O evento Make Music Brasil iniciou-se na Itaipu Binacional as 10:30h da manhã, com apresentação do grupo musical Jazz do Iguaçu formado por funcionários da empresa e foi encerrado as 11:30 do mesmo período.' },
            { type: 'image', src: [make_music_03, make_music_h1, make_music_h2], alt: 'Fotos: Marcos Labanca' },
            { type: 'text', text: ' ' },
            { type: 'text', text: 'Musicos integrantes da banda: Aurélio Berlinck, Felipe Trevisan, Romeu Rufino e Bruno Fontes.' },
            { type: 'text', text: 'No Jhonny Studio Bar no Jardim California, iniciou-se as 11:30 e terminou as 12:30, com as apresentações da Banda The Guardian e Kondor Pictus .' },
            { type: 'text', text: 'Músicos participantes: The Guardian Jhonny Garcia e Joce Garcia e Kondor Pictus (nome artístico).' },
            { type: 'image', src: [make_music_04, make_music_05, make_music_06], alt: 'Fotos: Marcos Labanca' },
            { type: 'text', text: ' ' },
            { type: 'text', text: 'As 14h foi iniciado no Cemei Barbara Matos Januario, na Rua Cisne Nª 1510 Morumbi III, com a apresentação dos alunos, e encerrando as 15:30h' },
            { type: 'image', src: [make_music_07], alt: 'Fotos: Marcos Labanca' },
            { type: 'text', text: ' ' },
            { type: 'text', text: 'No estudio do Polenta sito na Rua Grajau Nº 615 Parque Imperatriz foi iniciado as 15h com as apresentações dos grupos Conexão Fronteira (Reeage), Reação Quimica (Rock) e Jam Session (varios músicos)encerrando as 18h.' },
            { type: 'text', text: 'Reação Quimica Musicos: Giovani, Ariel, Jeronimo, Ivan e Polenta.' },
            { type: 'text', text: 'Conexão Fronteira Músicos: Samuel Jaimi, Polenta, Ricardo e Felipe.' },
            { type: 'text', text: 'Jam Session Musicos' },
            { type: 'text', text: 'ESTÚDIO DO POLENTA' },
            { type: 'image', src: [make_music_08, make_music_09, make_music_10], alt: 'Fotos: Estudio do Polenta' },
            { type: 'text', text: ' ' },
            { type: 'text', text: 'O Make Music Brasil no teatro Otília Schimmelpfeng (Teatro Barracão ), Av. República Argentina n.º 4073 Jardim Tarobá, iniciou-se as 17h30min com as apresentações musicais de Chico do Apito Sons da Natureza, Kondor Pictus, Roxck Brazil, The Guardian, Barracuda e Reação Química, sendo que duas atrações internacionais, Chico do Apito Bolívia e Barracuda Argentina, tendo se encerrado as 23h45min. Todas as apresentações foram transmitidas pelo canal do YouTube: https://www.youtube.com/live/CWm8csuTrRM?si=_lV6KxrByVAw8PTu' },
            { type: 'text', text: 'TEATRO OTÍLIA SHIMELPFENG (TEATRO BARRACÃO)' },
            { type: 'image', src: [make_music_11, make_music_12], alt: 'Fotos: Marcos Labanca' },
            { type: 'image', src: [make_music_13, make_music_14], alt: 'Fotos: Marcos Labanca' },
            { type: 'text', text: ' ' },
            { type: 'text', text: ' ' },
            { type: 'text', text: 'Show de bandas do encerramento do make Music Brasil 2024 Foz do Iguaçu.' },
            { type: 'text', text: 'Marco das Três Fronteiras iniciou as apresentações musicais a partir das 19:30, com os shows A Lenda das Cataratas e Boleadeira Argentina, e se encerrando as 21h.' },
            { type: 'image', src: [make_music_15, make_music_16], alt: 'Fotos: Marcos Labanca' },
            { type: 'image', src: [make_music_17], alt: 'Fotos: Marcos Labanca' },
            { type: 'text', text: 'Reunião de Organização do Make Music Brasil na Fundação Cultural  entre Giovani Fagundes coordenador regional do Make Music, Joaquim Rodrigues da Costa Diretor-Presidente da Fundação Cultura e Kalito Stockel professor e vereador de Foz do Iguaçu.' },
            { type: 'image', src: [make_music_18], alt: 'Fotos: Marcos Labanca' },
            { type: 'text', text: 'Reunião na Secretária de Turismo referente ao Make Music Brasil com a participação de Giovani Fagundes coordenador regional do Make Music. Kalito Stockel, Miguel Dal Olmo de Campos e Lorenço Kurten. ' },
            { type: 'text', text: 'Patrocínio:' },
            { type: 'image', src: [logo_pmfi], alt: 'Patrocínio' },
            { type: 'text', text: ' ' },
            { type: 'text', text: ' ' },
        ],
        copyright: '© 2024 ITAI',
    },
];

export default events;
