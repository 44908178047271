import img01 from '../assets/timeline/timeline01.png';
import img02 from '../assets/timeline/timeline02.png';
import img03 from '../assets/timeline/timeline03.png';
import img04 from '../assets/timeline/timeline04.png';
import img05 from '../assets/timeline/timeline05.png';

export const timelineContent = [
    {
        id : 0,
        year : '1996',
        content : 'Criação do ITAI com o objetivo de impulsionar o desenvolvimento tecnológico da região oeste do Paraná.',
        image : img01
    },
    {
        id : 1,
        year : '1997',
        content : 'Apoio intenso do ITAI na criação do curso de Ciências da Computação na UNIOESTE - Campus de Foz do Iguaçu.',
        image : img02
    },
    {
        id : 2,
        year : '2003',
        content : 'Estruturação da Fundação PTI - BR, a partir da demanda da ITAIPU Binacional e apoio da UNIOESTE.',
        image : img03
    },
    {
        id : 3,
        year : '2009-2016',
        content : 'Desenvolvimento intenso do projetos e ações  - Criação e operação do LASSE - Laboratório de Automação e Simulação de Sistemas Elétricos; Projetos aprovados em editais ITAIPU,FINEP, ELETROBRÁS, COPEL/ANEEL.',
        image : img04
    },
    {
        id : 4,
        year : '2018-Atualmente',
        content : 'Reformulação dos objetivos e linhas de atuação do ITAI, Apoio e desenvolvimento de ações científicas e tecnológicas para instituições públicas e privadas.',
        image : img05
    }
]