import { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import emailjs from '@emailjs/browser';
import './InstitutionForm.css';

function InstitutionForm() {
    const [values, setValues] = useState({});
    const form = useRef();

    const handleChange = ({ target: { name, value } }) => {
        setValues(({ ...values, [name]: value }))
    }

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_vxfx0bj', 'template_wx7s3wv', form.current, 'xLo7uU70_lFfZfzMZ')
            .then((result) => {
                alert("Formulário preenchido com sucesso, a equipe do ITAI entrará em contato.");
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset();
    };

    return (
        <form className='institution-form' ref={form} onSubmit={sendEmail}>
            <TextField
                placeholder="insira seu nome"
                required
                label="Nome"
                name="name"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira seu cargo"
                required
                label="Cargo"
                name="position"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira o nome da Instituição de Ensino"
                required
                label="Instituição de Ensino"
                name="company"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira o cnpj da Instituição de Ensino"
                required
                label="CNPJ"
                name="cnpj"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira o endereço da Enstituição de Ensino"
                required
                label="Endereço"
                name="address"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira seu telefone no formato 45 999999999"
                required
                label="Telefone"
                name="phone"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira seu email no formato seuemail@provedor.com"
                required
                label="Email"
                name="email"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira sua resposta"
                required
                label="Como ficou sabendo do Portal de Estágios do ITAI?"
                name="message"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <Button className='form-content button-form' type="submit" color="primary">Cadastrar</Button>
        </form>
    )
}

export default InstitutionForm;