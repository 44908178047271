import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { TextareaAutosize } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './styles.css';
import emailjs from '@emailjs/browser';

function ExtendProjectForm() {

    const [values, setValues] = useState({})
    const form = useRef();

    const handleChange = ({ target: { name, value } }) => {
        setValues(({ ...values, [name]: value }))
    }

    const registerSubmit = (event) => {
        event.preventDefault();

        emailjs.sendForm('gmailMessage', 'template_buxhohx', form.current, 'user_9kJbpTkkmqwE1KOwv0AGa')
            .then((result) => {
                alert("Formulário enviado com sucesso! Fique atento ao e-mail entraremos em contato!")
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <form className="box-form" ref={form} onSubmit={registerSubmit}>
            <div className='content-input'>
                <TextField
                    placeholder="insira seu nome"
                    required
                    label="Nome"
                    name="nome"
                    variant="outlined"
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    placeholder="insira seu email no formato seuemail@provedor.com"
                    label="Email"
                    name="email"
                    type="email"
                    required
                    variant="outlined"
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    placeholder="insira seu telefone no formato 45 999999999"
                    label="Telefone"
                    name="telefone"
                    required
                    variant="outlined"
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    placeholder="se houver vínculo com o projeto"
                    label="Empresa/Instituição"
                    name="empresa"
                    variant="outlined"
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    placeholder="se houver vínculo com a empresa"
                    label="Cargo"
                    name="cargo"
                    variant="outlined"
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                <h3>Informações sobre o Projeto</h3>
                <TextField
                    placeholder="titulo do projeto"
                    label="Titulo"
                    name="titulo"
                    variant="outlined"
                    required
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
                {/* <SearchSelect label="Temas" /> */}
                <TextareaAutosize
                    className='text-area'
                    placeholder="Descreva o projeto"
                    name="descricao"
                    required
                    onChange={handleChange}
                    margin="normal"
                />
                <TextareaAutosize
                    className='text-area'
                    placeholder="Justificativa"
                    name="justifica"
                    required
                    onChange={handleChange}
                    margin="normal"
                />
                <TextareaAutosize
                    className='text-area'
                    placeholder="Recursos"
                    name="recursos"
                    required
                    onChange={handleChange}
                    margin="normal"
                />
                <TextareaAutosize
                    className='text-area'
                    placeholder="Captação de recursos"
                    name="captacao"
                    variant="outlined"
                    onChange={handleChange}
                    margin="normal"
                />
                <Button className="button-form" fullWidth type="submit" color="primary">Cadastrar</Button>
            </div>
        </form>
    );
}

export default ExtendProjectForm;