import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import NewsCard from '../NewsCard/NewsCard';
import axios from 'axios';
import genericNews from '../../assets/genericNews.png';
import settings from '../../util/newsSliderSettings';
import './NewsSlider.css';

function NewsSlider({ title, category }) {
    const [news, setNews] = useState([]);
    const parsedNews = [];
    const parser = new DOMParser();

    useEffect(() => {
        axios.get('https://itai.org.br/noticias/?rest_route=/wp/v2/posts&_embed')
            .then(res => {
                const sortedNews = res.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setNews(sortedNews);
            })
            .catch(error => console.log(error))
    }, [])

    news.map((item) => {
        if (item.categories[0] === category) {
            const parsedExcerpt = parser.parseFromString(item.excerpt.rendered, 'text/html').body.textContent;
            const featuredMedia = item._embedded['wp:featuredmedia'];
            const image = featuredMedia ? featuredMedia[0].media_details.sizes.medium_large.source_url : genericNews;

            parsedNews.push(
                <NewsCard
                    image={image}
                    title={item.title.rendered}
                    excerpt={parsedExcerpt}
                    link={item.link}
                />
            )
        }

        return null;
    })

    return (
        <div className="partnersResponsive noticias">
            <h2>{title}</h2>
            <Slider {...settings}>
                {parsedNews}
            </Slider>
        </div>
    );
}

export default NewsSlider;