import React from 'react';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import AssociateSwitch from '../../components/AssociateSwitch';
import TestemonialCard from '../../components/TestemonialCard/TestemonialCard';
import { associates } from '../../data/associateData';
import associateIMG from  '../../assets/banners/associates.png';
import AssociateSearch from '../../components/AssociateSearch/AssociateSearch';
import './styles.css'

const Associate = () => {
    return(
        <PageDefault>
			<ScrollToTopOnMount />
            <SlideDefault 
                h2="Associados Profissionais" 
                textTwo="O Programa de Associados Profissionais do ITAI é uma oportunidade para todo profissional qualificado que tenha interesse em desenvolver atividades, projetos, pesquisas e estudos junto ao ITAI."
                buttonRenderization={false}
                image={associateIMG}
            />
            <AssociateSwitch />
            <AssociateSearch />
            <section className='testemonials'>
                {associates.map((associate) => {
                    return <TestemonialCard
                        name={associate.name}
                        statement={associate.statement}
                        img={associate.image}
                    />
                })}
            </section>
        </PageDefault>
    );
}


export default Associate;