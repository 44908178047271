import { useParams } from 'react-router-dom';
import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import ScroolToTopOnMount from '../../components/ScrollToTopOnMount';
import { solutions } from '../../data/solutions';
import SolutionSquare from '../../components/SolutionSquare/SolutionSquare';
import CourseCard from '../../components/CourseCard/CourseCard';
import { FiMail } from 'react-icons/fi';
import './styles.css';

function GenericSolutionPage() {
    const { id } = useParams();
    const solution = solutions.find(item => item.id === Number(id));

    return (
        <PageDefault>
            <ScroolToTopOnMount />
            <SlideDefault 
                h2={solution.title}
                text={solution.firstText}
                image={solution.image}
            />
            <div className="solution-text">
                <h2>{solution.title}</h2>
                <p>{solution.secondText}</p>
                <hr />
            </div>
            
            <h2 className='solutions-title'>{solution.squaresTitle}</h2>
            
            {solution.topics && <div className="services-squares">
                {solution.topics.map(topic =>
                    <SolutionSquare
                        title={topic}
                    />
                )}
            </div>}

            {solution.courses &&  <div className="courses-section">
                {solution.courses.map(course => 
                    <CourseCard 
                        {...course}
                    />
                )}
            </div>}

            <p className='budget' ><FiMail className='icon' />Solicitar orçamento – contato@itai.org.br</p>

        </PageDefault>
    )
}

export default GenericSolutionPage;