import arrowLeft from '../../assets/arrowLeft2.png';
import './PrevArrow.css';

function PrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={{ ...style, display: 'block', margin: '0px auto' }} onClick={onClick}>
			<img src={arrowLeft} alt="Ir para a esquerda" style={{maxWidth: 'none'}} />
		</div>
	);
}

export default PrevArrow;