import { useState } from 'react';
import TextBox from '../TextBox/TextBox';

import './ImageDisplay.css';

function ImageDisplay(props) {
    const imgs = props.images;
    const [mainImage, setMainImage] = useState(imgs[0]);
    const miniImages = [];

    for (let i = 0; i < imgs.length; i++) {
        miniImages.push(<img 
            src={imgs[i]} 
            className='mini-images' 
            onClick={event => setMainImage(event.target.src)} 
            alt=''
        />);
    }

    return (
        <section className='image-display'>
            <div className='row main-row'>
                <img src={mainImage} className='main-image' alt=''/>
                <TextBox
                    title={props.title}
                    underline={false}
                    rights={props.rights}
                    rightsLink={props.rightsLink}
                    btn={props.btn}
                    linkTo={props.linkTo}
                    className='associates-txt-box'
                > 
                    {props.text}
                </TextBox>
            </div>
            {/* <div className='row mini-row'>
                {miniImages}            
            </div>
            <p className='warning'>Clique nas imagens acima</p> */}
        </section> 
    )
}

export default ImageDisplay;