import { GrClose } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import './styles.css';

function EventsModal(props) {
    const { content, title, copyright } = props;
    const history = useHistory();

    return (
        <>
            <div className="case-modal-background" onClick={() => history.push('/evento')} />
            <div className="case-modal" role="dialog" aria-labelledby="modal-title" aria-modal="true">
                <GrClose className='close-icon' onClick={() => history.push('/evento')} aria-label="Close modal" />
                <h2 id="modal-title" className='case-modal-title'>{title}</h2>
                <div className='content-section'>
                    {content.map((item, index) => {
                        if (item.type === 'image') {
                            const images = Array.isArray(item.src) ? item.src : [item.src];
                            return (
                                <div key={index} className="flex-container">
                                    <div className="image-container">
                                        {images.map((src, imgIndex) => (
                                            <img key={imgIndex} src={src} alt={item.alt || 'Imagem do projeto'} className="case-modal-image" />
                                        ))}
                                    </div>
                                    {index + 1 < content.length && content[index + 1].type === 'text' && (
                                        <div className="text-section">
                                            <p>{content[index + 1].text}</p>
                                        </div>
                                    )}
                                </div>
                            );
                        } else if (item.type === 'text' && (index === 0 || content[index - 1].type !== 'image')) {
                            return (
                                <div key={index} className="text-section">
                                    <p>{item.text}</p>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
                {copyright && <p className='copyright'>{copyright}</p>}
            </div>
        </>
    );
}

export default EventsModal;
