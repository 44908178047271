import { importAll } from '../../util/importAll';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import LogosHolder from '../../components/LogosHolder/LogosHolder';
import auditorium from '../../assets/banners/ies.png';
import './IES.css';

function IES() {
    const logos = importAll(require.context('../../assets/ies', false, /\.(png|jpe?g|svg)$/));

    return (
        <PageDefault>
            <ScrollToTopOnMount />
            <SlideDefault 
                h2='Instituições de Ensino'
                text='Ser parceiro do ITAI significa agilidade e dedicação técnica empenhada nas ações e projetos em conjunto.
                O ITAI possibilita à sua empresa/instituição a conexão com outros empreendedores e profissionais, inserindo seus representantes na rede de articulação que oferta produtos e soluções tecnológicas personalizadas.
                Sem dúvida, ser um PARCEIRO ITAI é uma excelente forma de agregar competências e aumentar a competitividade da sua organização.'
                image={auditorium}
            />
            <LogosHolder 
                title='Instituições de Ensino'
                array={logos}
            />
        </PageDefault>
    )
}

export default IES;