import { HiUserGroup } from "react-icons/hi";
import { AiOutlineLaptop, AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { GrDocumentVerified, GrMail } from 'react-icons/gr';
import { FaHandshake, FaWhatsapp } from 'react-icons/fa';
import { BiMailSend } from 'react-icons/bi';
import { MdDeviceHub } from 'react-icons/md';

export const company = [
    {
        key: 1,
        text: 'Os melhores candidatos já pré-selecionados!',
        container: <HiUserGroup size={40}/>
    },
    {
        key: 2,
        text: 'Processos digitais e atendimento personalizado!',
        container: <AiOutlineLaptop size={40}/>
    },
    {
        key: 3,
        text: 'Preparação de documentos e gerenciamento das contratações!',
        container: <GrDocumentVerified size={40}/>
    },
    {
        key: 4,
        text: 'Suporte aos supervisores e estagiários atendendo a Lei 11.788/2008!',
        container: <FaHandshake size={40}/>
    }
]

export const institution = [
    {
        key: 1,
        text: 'Encaminhamento rápido das vagas de estágios para seus contatos!',
        container: <BiMailSend size={40} />
    },
    {
        key: 2,
        text: 'Preparação de documentos e gerenciamento das contratações!',
        container: <GrDocumentVerified size={40}/>
    },
    {
        key: 3,
        text: 'Interação ágil entre empresa, estudante e universidade!',
        container: <MdDeviceHub size={40}/>
    },
    {
        key: 4,
        text: 'Suporte aos orientadores e estagiários atendendo a Lei 11.788/2008!',
        container: <FaHandshake size={40}/>
    },
    {
        key: 5,
        text: 'Parcerias para projetos e ações do ITAI!',
        container: <AiOutlineFundProjectionScreen size={40}/>
    }
]

export const contacts = [
    {
        key: 1,
        text: <a href="https://wa.me/message/B6CYI3GCDYCEH1">Telefone e Whatsapp para contato: 45 35767113</a>,
        container: <FaWhatsapp size={40} />
    },
    {
        key: 2,
        text: 'Email para contato: estagios@itai.org.br',
        container: <GrMail size={40} />
    }
]