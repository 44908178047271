import './styles.css';

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import PageDefault from '../../components/PageDefault';
import ExpertisesComponent from '../../components/ExpertisesComponent';

function Expertises() {

	return (
		<PageDefault>
			<ScrollToTopOnMount />
			<div className="expertises">
				
				<h2>Áreas de Atuação</h2>
				<ExpertisesComponent />
			</div>
		</PageDefault>
	);
}

export default Expertises;
