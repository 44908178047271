import React from 'react';
import './styles.css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import PageDefault from '../../components/PageDefault';
import SpandexCard from '../../components/SpandexCard/SpandexCard';
import { mvvText } from '../../data/mvv';
import equipeIMG from '../../assets/pti.png';
import DirectorBox from '../../components/DirectorBox/DirectorBox';
import directors from '../../data/directors';
import { maintainers, fiscal, technical } from '../../data/concils';
import Table from '../../components/Table/Table';

function About() {
	const tableHeads = ['Instituição', 'Titular', 'Suplente'];

	return (
		<PageDefault>
			<ScrollToTopOnMount />
			<section className="about">
				<article className="figureITAI">
					<figure>
						<img src={equipeIMG} alt="ITAI" className="imageFigure" />
						<p id='imgCredits'>Kiko Sierich/PTI.</p>
					</figure>
					<div className="ITAI">
						<h2>O ITAI</h2>
						<p>
							O ITAI é uma associação civil, sem fins lucrativos, que possui qualificações de OSCIP - Organização de Sociedade Civil de Interesse Público. É também uma ICT - Instituição Científica e Tecnológica que possibilita a execução de projetos e pesquisas de alta performance, tecnologia e inovação. Atualmente localizada na sala 312A, no Edifício das Águas do Parque Tecnológico ITAIPU.
						</p>
					</div>
				</article>
			</section>
			<section className="tabSection">
				<div className="alignAll">
					<div className='mvvDiv'>
						{mvvText.map(item => <SpandexCard
								icon={item.icon}
								title={item.title}
								content={item.content}
								bgColor={item.bgColor}
							/>
						)}
					</div>

					<h2>Diretores do ITAI</h2>
					{directors.map((director) => 
						<DirectorBox {...director} />
					)}

					<Table 
						title='Mantenedores' 
						heads={tableHeads}
						tableArray={maintainers}
					/>

					<Table 
						title='Conselho Fiscal'
						heads={tableHeads}
						tableArray={fiscal}
					/>

					<Table 
						title='Conselho Técnico-Científico'
						heads={['Instituição', 'Formação', 'Titular']}
						tableArray={technical}
					/>

				</div>
			</section>
		</PageDefault>
	);
}

export default About;
