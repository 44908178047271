import React from 'react';
import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import eventsBanner from '../../assets/banners/events.png';
import EventsCard from '../../components/EventsCard/';
import events from '../../data/events';
import './styles.css';

function Index() {
    return (
        <PageDefault>
            <SlideDefault
                h2='Eventos promovidos pelo ITAI'
                text='O objetivo do Instituto é fomentar o envolvimento de pessoas, instituições e governos, promovendo um ecossistema de inovação para o desenvolvimento de ciência e tecnologia por meio de workshops, eventos, palestras, oficinas, apresentações culturais, capacitações e outros eventos sociais e corporativos.'
                image={eventsBanner}
            />
            <div className='cases-holder'>
                {events.map((item) =>
                    <EventsCard
                        id={item.id}
                        key={item.title}
                        title={item.title}
                        summary={item.summary}
                        content={item.content}
                        copyright={item.copyright}
                    />
                )}
            </div>
        </PageDefault>
    );
}

export default Index;

