import React from 'react';
import './styles.css';
import documentIMG from '../../assets/document.png';

function DownloadFile({ title, documentURL, imgRenderization }) {
	function handleDownload(e) {
		e.preventDefault();
		window.open(documentURL, '_blank')
	}
	return (
		<div className="downloadFile">
			{imgRenderization && (<img src={documentIMG} alt={title} />)}
			<h3>{title}</h3>
			<a href="/#" onClick={handleDownload}>Download</a>
		</div>
	);
}

export default DownloadFile;
