import './Table.css';

function Table(props) {
    const { title, heads, tableArray } = props;

    return (
        <div className='itai-table-holder'>
            <h2 className='table-title' >{ title }</h2>
            <hr className='table-line'/>
            <table className='itai-table'>
                <thead>
                    <tr>
                        {heads.map((head) => 
                            <th>{ head }</th>
                        )}
                    </tr>
                </thead>
                {tableArray.map((item) => 
                    <tr>
                        <td>{ item.institution }</td>
                        {item.formation && <td>{ item.formation } </td>}
                        <td>{ item.holder }</td>
                        {item.substitute && <td>{ item.substitute }</td>}
                    </tr>
                )}
            </table>
        </div>
    )
}

export default Table;