import React from 'react';

import { FaLinkedinIn, FaInstagram, FaFacebookF, FaWhatsapp } from 'react-icons/fa';

import LogoFooter from '../../assets/logofooter.png';

const Footer = () => {
    return (
        <>
            <div className="mainFooter">
                <div className="contactFooter">
                    <h2>Entre em Contato</h2>
                    <p>
                        <br /> +55 (45) 3576-7114
                        <br />contato@itai.org.br
                    </p>
                    <p>
                        <br /> Av. Tancredo Neves, 6731
                        <br /> Parque Tecnológico Itaipu
                        <br /> Foz do Iguaçu/PR - CEP: 85.867-970
                    </p>
                </div>
                <div className="socialFooter">
                    <div>
                        <img src={LogoFooter} alt="ITAI" className="logoFooter" />
                    </div>
                    <div>
                        <a
                            href="https://www.linkedin.com/company/itai/"
                            rel="noreferrer"
                            className="socialLinks"
                            target="_blank"
                        >
                            <FaLinkedinIn size={45} />
                        </a>
                        <a
                            href="https://www.instagram.com/itai.org.br"
                            rel="noreferrer"
                            className="socialLinks"
                            target="_blank"
                        >
                            <FaInstagram size={45} />
                        </a>
                        <a href="/#" className="socialLinks" rel="noreferrer" target="_blank">
                            <FaWhatsapp size={45} />
                        </a>
                        <a
                            href="https://www.facebook.com/itai.org.br"
                            rel="noreferrer"
                            className="socialLinks"
                            target="_blank"
                        >
                            <FaFacebookF size={45} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>Copyright &copy; 2020 ITAI - Instituto de Tecnologia Aplicada e Inovação</p>
            </div>
        </>
    );
}


export default Footer;