import React from 'react';
import Slider from 'react-slick';

import './styles.css';
import Button from '../../components/Button';

const SlideDefault = ({ h2, subtitle, text, textTwo, children, buttonRenderization, image }) => {

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: true,
	};
	return (
		<div className="slideDefault">
			<Slider {...settings}>
				<div className="slide-item">
					<section className="cta">
						<div className="cta-content">
							<h2>{h2}</h2>
							<h2>{subtitle}</h2>
							<div>
								<p>{text || children}</p>
								<p>{textTwo}</p>
							</div>
							{buttonRenderization && (<p className="buttonContentCTA">
								<Button title="Veja Mais" type="buttonContentCTA" url="/about" />
							</p>)}
						</div>
						<div className="float">
							{buttonRenderization && (<div className="buttonFloat">
								<Button title="Veja Mais" type="buttonCTA" url="/about" />
							</div>)}
						</div>
						<div className="cta-image">
							<div className="cta-cut">&nbsp;</div>
							<img src={image} alt="Veja Mais" />
						</div>
					</section>
				</div>
			</Slider>
		</div>
	);
}
export default SlideDefault;
