import React from 'react';
import './styles.css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import PageDefault from '../../components/PageDefault';
import Timeline from '../../components/Timeline/Timeline';

function History() {
	return (
		<PageDefault>
			<ScrollToTopOnMount />
			<div className="history">
				<Timeline />
			</div>
		</PageDefault>
	);
}

export default History;