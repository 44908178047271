import {AiOutlineFundProjectionScreen} from 'react-icons/ai';
import {FaHandshake} from 'react-icons/fa';
import {IoIosPeople} from 'react-icons/io';
import pesquisa from '../assets/banners/p&d.png';
import consultoria from '../assets/banners/consultancy.png';
import eventos from '../assets/banners/events.png';
import investments from '../assets/coursesBanners/investments.png';
import women from '../assets/coursesBanners/women.png';
import organization from '../assets/coursesBanners/organization.png';
import analisis from '../assets/coursesBanners/analisis.png';
import pidMini from '../assets/landingSolutions/p&dLanding.png';
import consultancyMini from    '../assets/landingSolutions/consultancyLanding.png';
import coursesMini from '../assets/landingSolutions/eventsLanding.png';

export const solutions = [
    {
        id: 0,
        image: pesquisa,
        imageMini: pidMini,
        icon: <AiOutlineFundProjectionScreen className='icon' />,
        title: 'Programa de Pesquisa, Desenvolvimento e Inovação (P&D+I)',
        secondTitle: 'P&D+I',
        firstText: 'Desde 1996 temos como objetivo impulsionar o avanço tecnológico por meio de Projetos de Pesquisa, Desenvolvimento e Inovação (P&D+I). São anos de estudo e dedicação em análises, gestão de portfolios, programas, projetos e processos.',
        secondText: 'O desafio de inovar envolve muita complexidade pois é preciso equilibrar a visão estratégica da organização e as diretrizes regulatórias das agências de fomento e programas de P&D+I. E é assim que o ITAI pode ajudar sua organização, realizando a gestão integrada do Programa de Pesquisa, Desenvolvimento e Inovação, com especialistas renomados com experiências em várias áreas.',
        squaresTitle: 'Áreas de atuação em P&D+I do ITAI:',
        topics: ['Elaboração de Projetos com Órgãos de Financiamento Públicos e Privados (ANEEL/ COPEL/ Eletrobrás)', 'Biogás e Bioenergia', 'Geração Distribuída', 'Baterias e Economia do Hidrogênio', 'Eletroquímica Aplicada e Novos Materiais', 'Automação e Sistemas', 'Inteligência Artificial e Computacional', 'Controle de Robôs', 'Gestão de Inovação em Ambientes de Tecnologia']
    },
    {
        id: 1,
        image: consultoria,
        imageMini: consultancyMini, 
        icon: <FaHandshake className='icon' />,
        title: 'Consultorias',
        secondTitle: 'Consultorias',
        firstText: 'O ITAI atua para que empresas alcancem os melhores resultados por meio da Inovação Corporativa. Fazemos isso com a expertise de profissionais de diversas áreas, com uma equipe que integra especialistas instituições de ensino, empresas e órgãos governamentais.',
        secondText: 'O principal core do ITAI é amparar os clientes na criação e estruturação organizacional, na capacitação intelectual dos colaboradores e na preparação para uma mudança voltada a cultura de inovação contínua. A consultoria abrange, modelos de negócio, projetos de inovação, desenvolvimento de novas ideias, serviços e produtos, bem como, a solução de problemas complexos e necessidades específicas nas mais diversas áreas de atuação.',
        squaresTitle: 'Conheça nossas Consultorias:',
        topics: ['Gestão e Negócios', 'Gestão da Inovação', 'Cidades Inteligentes', 'Biogás e Bioenergia', 'Geração Distribuída', 'Sistemas Isolantes para o Setor Elétrico', 'ISO 17025 em Laboratórios', 'Inteligência Artificial', 'Psicologia Social, do Trabalho e das Organizações']
    },
    {
        id: 2,
        image: eventos,
        imageMini: coursesMini,
        icon: <IoIosPeople className='icon' />,
        title: 'Cursos e Treinamentos',
        secondTitle: 'Cursos e Treinamentos',
        firstText: 'Nossos conteúdos de aprendizagem podem ser customizados sob medida para sua organização, por meio de cursos, palestras, oficinas, seminários, workshops, treinamento webinars e eventos em geral.',
        secondText: 'Somos especialistas em transformar ricos conteúdos de aprendizagem, utilizando os mais modernos formatos educacionais.',
        squaresTitle: 'Conheça nosso Portfólio',
        courses: [
            {
                id: 0,
                image: investments,
                area: 'Curso / Gestão e Negócios',
                title: 'Análise de Viabilidade Econômica de Investimentos',
                format: 'presencial',
                duration: '50h',
                conclusion: '90 dias',
                students: 'de 15 a 35 alunos',
                objective: 'Oportunizar aos alunos o conhecimento dos fundamentos teóricos e da aplicação do cálculo econômico e financeiro como requisito para a tomada de decisão sobre a alocação de recursos em empreendimentos industriais, comerciais e de serviços.',
                ementa: <ul>
                    <li>Cálculo de juros simples e compostos;</li>
                    <li>Comparação de alternativas de investimento;</li>
                    <li>Depreciação técnica;</li>
                    <li>Cálculo de índices de inflação;</li>
                    <li>Análise custo/benefício;</li>
                    <li>Métodos de análise de viabilidade dos investimentos: valor presente líquido (VPL), payback (tempo de retorno), taxa interna de retorno (TIR);</li>
                    <li>Substituição de equipamentos; e</li>
                    <li>Modelos de decisão econômica.</li>
                </ul>,
                coordination: ' Prof. Dr. Eduardo de Pintor'
            },
            {
                id: 1,
                image: women,
                area: 'Curso / Gestão e Negócios',
                title: 'Liderança Feminina',
                format: 'presencial',
                duration: '4h',
                conclusion: '1 dia',
                students: 'até 35 alunos',
                objective: 'O curso sobre liderança feminina visa promover a igualdade de gênero e empoderar as mulheres na liderança. Ele oferece ferramentas e técnicas para as mulheres enfrentarem desafios específicos em ambientes de liderança, como a falta de representatividade, estereótipos de gênero e barreiras de gênero. O curso também ajuda a mudar a cultura organizacional para ser mais inclusivo e promover a diversidade na liderança. Ao fazer um curso de liderança feminina, as mulheres podem melhorar suas habilidades de liderança, aumentar sua visibilidade e construir sua rede de contatos, o que pode ajudá-las a progredir em suas carreiras.',
                ementa: <ul>
                    <li>Barreiras e desafios enfrentados por mulheres na liderança e no ambiente de trabalho, incluindo a identificação de gênero e estereótipos de gênero;</li>
                    <li>Desenvolvimento de habilidades de liderança, como comunicação, resolução de problemas e tomada de decisão; e</li>
                    <li>Técnicas de comunicação assertiva e negociação para mulheres líderes.</li>
                </ul>,
                coordination: 'Prof. Dr. Sandra Cristiana Kleinschmitt'
            },
            {
                id: 2,
                image: organization,
                area: 'Curso / Gestão e Negócios',
                title: 'Motivação no Ambiente Organizacional',
                format: 'presencial',
                duration: '4h',
                conclusion: '1 dia',
                students: 'até 35 alunos',
                objective: 'O curso sobre motivação no ambiente organizacional pode ajudar a entender os fatores que influenciam o comportamento dos colaboradores e como incentivá-los para alcançar objetivos organizacionais. Isso pode resultar em uma equipe mais produtiva, engajada e satisfeita, o que pode levar a uma melhoria na cultura organizacional, na retenção de talentos e na eficiência dos processos. Além disso, um conhecimento aprofundado sobre motivação pode ajudar a liderança a tomar decisões mais estratégicas e efetivas em relação à gestão de pessoas.',
                ementa: <ul>
                    <li>Inteligência emocional;</li>
                    <li>Importância do trabalho em equipe;</li>
                    <li>Comunicação não violenta;</li>
                    <li>Mediação e solução de conflitos;</li>
                    <li>Cultura e valores organizacionais;</li>
                    <li>Gerenciamento de pessoas;</li>
                    <li>Engajamento com as metas; e</li>
                    <li>Como superar desafios pessoais e profissionais.</li>
                </ul>,
                coordination: 'Prof. Dr. Sandra Cristiana Kleinschmitt'
            },
            {
                id: 3,
                image: analisis,
                area: 'Curso / Gestão e Negócios',
                title: 'Planejamento Estratégico',
                format: 'presencial',
                duration: '4h',
                conclusion: '1 dia',
                students: 'até 35 alunos',
                objective: 'O curso sobre planejamento estratégico busca fornecer elementos para compreender as melhores práticas e técnicas para alcançar negócios a longo prazo, avaliar e priorizar as opções de ação, identificar oportunidades e estratégias, tomar decisões alinhadas às ações com a visão e valores da empresa. Ajuda no desenvolvimento de habilidades de liderança, comunicação e colaboração.',
                ementa: <ul>
                    <li>Conceitos de estratégia e planejamento estratégico;</li>
                    <li>Ferramentas e técnicas de análise estratégica, como análise SWOT e análise de cenários;</li>
                    <li>Elaboração de estratégias e planos de ação;</li>
                    <li>Implementação e monitoramento do plano estratégico;</li>
                    <li>Avaliação de resultados e tomada de decisões em dados</li>
                </ul>,
                coordination: ' Prof. Dr. Sandra Cristiana Kleinschmitt'
            },
        ]
    },
];