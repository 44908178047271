import PageDefault from '../../components/PageDefault';
import internImg from '../../assets/banners/internship.png';
import SlideDefault from '../../components/SlideDefault';
import InternshipSwitch from '../../components/InternshipSwitch/InternshipSwitch';
import NewsSlider from '../../components/NewsSlider/NewsSlider';
import './index.css';

function Index() {
    return (
        <PageDefault>
            <SlideDefault h2='Estágios' image={internImg}>
                O ITAI realiza a intermediação de estágios de ensino técnico e superior por meio do Programa de Estágios e viabiliza legalmente a interação entre empresas, instituições de ensino e estudantes.
                <br/><br/>
                Venha fazer parte dessa rede de parcerias!
            </SlideDefault>
            <NewsSlider title='Notícias sobre Estágios' category={3} />
            <InternshipSwitch />
        </PageDefault>
    )
}

export default Index;