import NextArrow from '../components/NextArrow/NextArrow';
import PrevArrow from '../components/PrevArrow/PrevArrow';

const settings = {
    focusOnSelect: false,
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    className: 'news-slider',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 1650,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,

                dots: true,
                arrows: true,
            },
        },
        {
            breakpoint: 1190,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,

                dots: true,
                arrows: true,
            },
        },
        {
            breakpoint: 820,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                infinite: true,
            },
        },
    ],

};

export default settings;