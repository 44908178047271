import './SpandexCard.css';

function SpandexCard({icon, title, content, bgColor}) {
    return (
        <div className='elastic-card' style={{ background: bgColor }}>
            { icon }
            <h2>{ title }</h2>
            <p>{ content }</p>
        </div>
    );
}

export default SpandexCard;