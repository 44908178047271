import { useState } from 'react';
import './LogoCard.css';

function LogoCard({ title, text, logo,}){
    const [showText, setShowText] = useState(false);
    const wrapperClass = showText ? 'logo-card opened' : 'logo-card closed';
    const buttonText = showText ? 'Mostrar menos' : 'Saiba mais';

    function handleClick(){
        setShowText(!showText)
    }

    return (
        <div className={wrapperClass} >
            <img src={logo} alt="imagem da empresa parceira" />
            {title ? <p className='title'><strong>{ title }</strong></p> : null}
            {showText & text !== '' ? <p>{ text }</p> : null}
            {text !== '' && text ? <button onClick={handleClick} className='logo-btn'>{ buttonText }</button> : null}
        </div>
    )
}

export default LogoCard;