import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import PageDefault from "../../components/PageDefault";
import { FiMail } from 'react-icons/fi';
import "./styles.css";

function Contact() {
  return (
    <PageDefault>
      <ScrollToTopOnMount />
      <section className="all">
        <div className="contact">
          <h2>Entre em contato</h2>
          <div className="forms">
            <div className="form-contact">
              <ul>
              <li>
                  <h3>Contato Geral</h3>
                  <p><FiMail className="icon"/> contato@itai.org.br</p>
                </li>
                <li>
                  <h3>Secretaria Executiva</h3>
                  <p><FiMail className="icon"/> secretaria@itai.org.br</p>
                </li>
                <li>
                  <h3>Estágios</h3>
                  <p><FiMail className="icon"/> estagios@itai.org.br</p>
                </li>
                <li>
                  <h3>Programas e Projetos</h3>
                  <p><FiMail className="icon"/>projetos@itai.org.br</p>
                </li>
                <li>
                  <h3>Financeiro e Orçamento</h3>
                  <p><FiMail className="icon"/> financeiro@itai.org.br</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <iframe
          className="map"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          title="mapa"
          src="https://maps.google.com/maps?hl=en&amp;q=ITAI - Instituto de Tecnologia Aplicada e Inovação, Av. Tancredo Neves, 6731 - Porto Belo, Foz do Iguaçu - PR, 85867-970&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </section>
    </PageDefault>
  );
}

export default Contact;