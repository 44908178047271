import React from 'react';
import './styles.css';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import PageDefault from '../../components/PageDefault';
import Clients from '../../components/Clients';
import SlideDefault from '../../components/SlideDefault';
// import ImageDisplay from '../../components/ImageDisplay/ImageDisplay';
import landing from '../../assets/banners/landing.png';
// import bannerworkshopturismo from '../../assets/banners/divulgacaoworkshop.png';
import NewsSlider from '../../components/NewsSlider/NewsSlider';
import { solutions } from '../../data/solutions';
import LandingSolutionCard from '../../components/LandingSolutionCard/LandingSolutionCard';


function LandingPage() {
	// const images = [bannerworkshopturismo];
	return (
		<PageDefault>
			<ScrollToTopOnMount />
			<SlideDefault
				h2="ITAI"
				subtitle="Instituto de Tecnologia Aplicada e Inovação"
				image={landing}
			/>
				{/* <ImageDisplay
					images={images}
					title="2º Workshop de Turismo, Inovação e Negócios"
					btn="Inscreva-se"
					linkTo="https://forms.gle/QQRVqTghs6zFNQYm6"
					text="2º Workshop de Turismo, Inovação e Negócios"
				/> */}
			{/* <InscreverButton title="Inscrição 2º Workshop de Turismo, Inovação e Negócios" documentURL="https://forms.gle/QQRVqTghs6zFNQYm6" /> */}
			<NewsSlider title='Notícias' category={1} />


			<div className='landing-solutions-background'>
				<div className='landing-solutions'>
					<h3>Nossas Soluções</h3>
					<p>Oferecemos soluções personalizadas para sua empresa ou projeto.</p>
					<div className="cards-holder">
						{solutions.map(item =>
							<LandingSolutionCard
								image={item.imageMini}
								id={item.id}
								title={item.secondTitle}
								text={item.firstText}
							/>
						)}
					</div>
				</div>
			</div>

			<Clients />
		</PageDefault>
	);
}

export default LandingPage;
