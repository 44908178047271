import { importAll } from '../util/importAll';
const logos = importAll(require.context('../assets/empresas', false, /\.(png|jpe?g|svg)$/));

export const companies = [
    {
        id: 1,
        name: 'OILFLEX',
        title: 'Consultoria e Projetos na área de Energia',
        text: 'Empresa Oilflex há 16 anos oferecendo soluções ao setor elétrico, na área de isolantes líquidos e gasosos, bem como na gestão de projetos ambientais e soluções de operação de laboratórios e de equipamentos em campo. Foco na operação dos clientes e na análise de desempenho estatístico dos índices de produção.',
        image: logos[0].default
    },
    {
        id: 2,
        name: 'GW ENERGIA',
        title: 'Consultoria Técnica e Regulatória do Setor de Energia (Especializada em Biogás e Biometano)',
        text: 'A GW Energia é uma consultoria técnica e regulatória, especializada em projetos de biogás e biometano. Atua no desenvolvimento de projetos de energias renováveis e biocombustíveis com uma equipe técnica e experiente em temas como regulação do setor elétrico (ANEEL), regulação do setor de gás natural e biometano (ANP), modelagem de negócio, estudo de viabilidade técnica-econômica, captação de investimento, projeto e especificação técnica para plantas de biogás e biometano, créditos de carbono, inteligência de mercado e projetos de P&D+I.',
        image: logos[1].default
    },
    {
        id: 3,
        name: 'MASTER BIODIGESTOGRES',
        title: 'Serviços de Engenharia',
        text: 'A Master Biodigestores é uma empresa de engenharia especializada no dimensionamento e execução de biodigestores e sistemas para tratamento de biomassa residual. A empresa também fornece e instala todos os equipamentos necessários para operação de usinas de energia a biogás, tanto para aproveitamento na geração de energia elétrica quanto térmica.',
        image: logos[2].default
    },
    {
        id: 4,
        name: 'GJ2W TECH',
        title: 'Desenvolvimento de Programas Computacionais e Serviços Administrativos',  
        text: 'A GJ2W oferece soluções para diferentes setores da indústria em projetos de engenharia de software e gestão de times de TI. Entendimento de necessidades, desenvolvimento das soluções e suporte continuado são a base do tripé de atuação da empresa. A GJ2W busca incessantemente entregar aos seus clientes serviços com rigor técnico, melhores práticas e tecnologias mais relevantes do mercado.',
        image: logos[3].default
    },
    {
        id: 5,
        name: 'SÓ CACHOEIRAS',
        title: 'Desenvolvimento de Portais, Provedores de Conteúdo e outros Serviços de Informação na Internet',
        text: '',
        image: logos[4].default
    },
    {
        id: 6,
        name: 'POWER MATTECH ',
        title: 'Equipamentos Eletrônicos',
        text: 'A Powermattech tem como objetivo fornecer soluções de tecnologia com qualidade para o setor produtivo, principalmente com Projetos de Sistemas e Equipamentos automatizados para setor de energia e da indústria, melhorando os resultados dos processos e facilitando o acesso a todas as informações da cadeia de produção. A empresa é formada por profissionais que atuam no setor de energia e desenvolvimento tecnológico há mais de 20 anos, em projetos de transformação, com compromisso ambiental, visando a integração de tecnologias ao bem-estar da cadeia produtiva.',
        image: logos[5].default
    },
    {
        id: 7,
        name: 'QUANTIKA',
        title: 'Intermediação e Agenciamento de Serviços e Negócios',
        text: '',
        image: logos[6].default
    },
    {
        id: 8,
        name: 'Um por Todos Digital',
        title: 'Desenvolvimento de Portais, Provedores de Conteúdo e outros Serviços de Informação na Internet',
        text: '',
        image: logos[7].default
    },
    {
        id: 9,
        name: 'Cresol',
        title: 'Cooperativa de Crédito que fornece soluções financeiras com excelência por meio do relacionamento',
        text: '',
        image: logos[8].default
    },
]