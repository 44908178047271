import { CgOrganisation } from 'react-icons/cg';
import { AiFillIdcard, AiFillProject } from "react-icons/ai";
import { MdGroupAdd } from "react-icons/md";
import { HiSearch } from "react-icons/hi";
import { FaMoneyBillWave, FaMoneyBillAlt, FaUserTag, FaUserAlt, FaRegHandshake} from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { GiGraduateCap } from 'react-icons/gi';
import { BsBook } from 'react-icons/bs';
import { AiOutlineLineChart, AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { RiFilePaperLine } from 'react-icons/ri';
import { BsPeopleFill } from 'react-icons/bs';
import fabio from '../assets/associates/Fabio.jpeg';
import joao from '../assets/associates/Joao.jpg';
import barbara from '../assets/associates/Barbara.png';

export const associateAdvantages = [
    {
      key: 1,
      text: "Acesso à infraestrutura institucional e física do ITAI",
      container: <CgOrganisation size={40}/>,

    },
    {
      key: 2,
      text: "Benefícios para líderes de grupos técnicos/específicos",
      container: <FaUserAlt size={40}/>,

    },
    {
      key: 3,
      text: "Descontos em cursos oferecidos pelo ITAI",
      container: <FaMoneyBillAlt size={40}/>,
    },
    {
      key: 4,
      text: "Facilidade de contratação/submissão de projetos",
      container: <AiFillProject size={40}/>,
    },
    {
      key: 5,
      text: "Facilidade em participar de grupos técnicos/específicos",
      container: <MdGroupAdd size={40}/>,
    },
    {
      key: 6,
      text: "Isenção da taxa anual nos 2 primeiros anos",
      container: <FaMoneyBillWave size={40}/>,
    },
    {
      key: 7,
      text: "Ser representante do ITAI em negociações iniciais para captação de recursos",
      container: <AiFillIdcard size={40}/>,
    }
] 

export const associateRequired = [
  {
    key: 1,
    text: "Pessoa física",
    container: <FaUserAlt size={40}/>,

  },
  {
    key: 2,
    text: "Profissional liberal; ou",
    container: <FaUserTag size={40}/>,

  },
  {
    key: 3,
    text: "Profissional especialista em determinadas áreas de atuação profissional; ou",
    container: <ImProfile size={40}/>,
  },
  {
    key: 4,
    text: "Profissional de setores de pesquisa e ensino, ligados a instituições de ensino.",
    container: <HiSearch size={40}/>,
  }
] 

export const associateAction = [
  {
    key: 1,
    text: 'Cursos de extensão',
    container: <BsBook size={40}/>
  },
  {
    key: 2,
    text: 'Pós-graduação',
    container: <GiGraduateCap size={40}/>
  },
  {
    key: 3,
    text: 'Consultoria',
    container: <AiOutlineLineChart size={40}/>
  },
  {
    key: 4,
    text: 'Protocolar ou submeter projetos em nome do ITAI por meio de Edital, Chamamento Público e outros meios',
    container: <AiOutlineFundProjectionScreen size={40}/>
  },
  {
    key: 5,
    text: 'Propor parcerias com outras Instituições para desenvolvimento de pesquisas ou projetos',
    container: <FaRegHandshake size={40}/>
  },
  {
    key: 6,
    text: 'Trabalhar em contratações de futuras parceiras do ITAI',
    container: <HiOutlinePencilAlt size={40}/>
  },
  {
    key: 7,
    text: 'Apresentar propostas potenciais que podem ser desenvolvidas pelo Instituto',
    container: <RiFilePaperLine size={40}/>
  },
  {
    key: 8,
    text: 'Outras atividades de interesse mútuo',
    container: <BsPeopleFill size={40}/>
  },
]

export const associates = [
  {
    id: 1,
    name: 'Fabio Plut Frenandes',
    statement: 'O programa de profissionais associados do ITAI possibilita que empreendedores de pesquisa e desenvolvimento tenham relações institucionais com o ecossistema de inovação do oeste do Paraná e fundos de financiamento, acelerando o desenvolvimento regional.',
    image: fabio
  },
  {
    id: 2,
    name: 'João Daniel dos Santos Mendonça',
    statement: 'Fazer parte da equipe de profissionais do ITAI é um privilégio e representa uma grande responsabilidade de poder contribuir com o desenvolvimento da nossa região e oportunidade pessoal e profissional de trabalhar com um time comprometido com a inovação.',
    image: joao
  },
  {
    id: 3,
    name: 'Bárbara Françoise Cardoso Bauermann',
    statement: 'O programa de associados profissionais do ITAI possibilita a interação interinstitucional e parcerias público-privadas no desenvolvimento de ações que melhoram o desenvolvimento regional.',
    image: barbara
  }
]