import { useState } from 'react';
import './AssociateCard.css';

function AssociateCard(props) {
    const [open, setOpen] = useState(false);
    const {
        nome, 
        titulo, 
        lattes, 
        // area, 
        atuacao, 
        email, 
        graduacoes, 
        especializacoes,
        mestrados, 
        doutorados, 
        image
    } = props;
    

    return (
        <div className={!open ? 'associate-card close' : 'associate-card open'}>
            <div className='visible'>
                <img src={image} alt="foto de perfil do associado" />
                <div>
                    <h3>{ nome }</h3>
                    <p className='titulo'>{ titulo }</p>
                    <a href={ lattes } target='_blank' rel='noreferrer' className='lattes'>Curriculo Lattes</a>
                    <p className='area-txt'><strong>Áreas de atuação:</strong> { atuacao }</p>
                   {!open && <button 
                        className='associate-btn' 
                        onClick={() => setOpen(true)}
                    >
                        <strong>Mostrar mais</strong>
                    </button>}
                </div>
            </div>
            {open && <div className='add-information'>
                {graduacoes && <p><strong>Graduação:</strong> { graduacoes }</p>}
                {especializacoes && <p><strong>Especialização:</strong> { especializacoes }</p>}
                {mestrados && <p><strong>Mestrado:</strong> { mestrados }</p>}
                {doutorados && <p><strong>Doutorado:</strong> { doutorados }</p>}
                <p><strong>Email para contato:</strong> { email }</p>
                <button
                    className='associate-btn'    
                    onClick={() => setOpen(false)}
                >
                    Mostrar menos
                </button>
            </div>}
        </div>
    )
}

export default AssociateCard;