import React from 'react';
import './TimelineBlock.css';

function TimelineBlock({ id, year, content, image,  }) {
    return (
        <div className='timeline-block'>
            {id % 2 === 0 ? <img src={image} className='left-image' alt='' /> : null}
            <div className='text-div'>
                <p>{ content }</p>
            </div>
            <div className='year-div'>
                <p>{ year }</p>
            </div>
            {id % 2 !== 0 ? <img src={image} className='right-image' alt='' /> : null}
        </div>
    );
}

export default TimelineBlock;