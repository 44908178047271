import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import { companies } from '../../data/companies';
import LogosHolder from '../../components/LogosHolder/LogosHolder';
import hands from '../../assets/banners/companies.png';
import './styles.css';

function Companies() {
    return (
        <PageDefault>
            <ScrollToTopOnMount />
            <SlideDefault 
                h2='Um hub de inovação'
                text='Ser parceiro do ITAI significa agilidade e dedicação técnica empenhada nas ações e projetos em conjunto.
                O ITAI possibilita à sua empresa/instituição a conexão com outros empreendedores e profissionais, inserindo seus representantes na rede de articulação que oferta produtos e soluções tecnológicas personalizadas.
                Sem dúvida, ser um PARCEIRO ITAI é uma excelente forma de agregar competências e aumentar a competitividade da sua organização.'
                image={hands}
            />
            <LogosHolder 
                title="Empresas que já se conectaram conosco"
                array={companies}
            />
        </PageDefault>
    )
}

export default Companies;