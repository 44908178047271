import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import bannerImg from '../../assets/banners/cases.png';
import CaseCard from '../../components/CaseCard/CaseCard';
import cases from '../../data/cases';
import './styles.css';

function index() {
    return (
        <PageDefault>
            <SlideDefault 
                h2='Projetos Desenvolvidos'
                text='Executamos projetos em Pesquisa, Desenvolvimento e Inovação (PD&I) com foco no ciclo completo dos projetos, desde estudos aplicados a inserção no mercado, tendo como destaque os projetos estratégicos em Economia, Negócios, Fontes Alternativas de Energia,  Armazenamento de Energia, Educacional e Cultural.'
                image={bannerImg}
            />

            <div className='cases-holder'>
                {cases.map((item) => 
                    <CaseCard 
                        title={item.title}
                        summary={item.summary}
                        text={item.text}
                        images={item.images}
                        copyright={item.copyright}
                    />
                )}
            </div>
            
        </PageDefault>
    )
}

export default index;