import { importAll } from '../../util/importAll';
import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import tech from '../../assets/banners/tech.png';
import LogosHolder from '../../components/LogosHolder/LogosHolder';
import './InstSec.css';

function InstSec() {
    const logos = importAll(require.context('../../assets/instSec', false, /\.(png|jpe?g|svg)$/));

    return (
        <PageDefault>
            <SlideDefault 
                h2="Organizações Representativas"
                text='Ser parceiro do ITAI significa agilidade e dedicação técnica empenhada nas ações e projetos em conjunto.
                O ITAI possibilita à sua empresa/instituição a conexão com outros empreendedores e profissionais, inserindo seus representantes na rede de articulação que oferta produtos e soluções tecnológicas personalizadas.
                Sem dúvida, ser um PARCEIRO ITAI é uma excelente forma de agregar competências e aumentar a competitividade da sua organização.'
                image={tech}
            />
            <LogosHolder 
                title='Organizações Representativas'
                array={logos}
            />
        </PageDefault>
    )
}

export default InstSec;