import './TextBanner.css';

function TextBanner(props) {
    return (
        <section className="text-banner">
            <h2>{props.children}</h2>
        </section>
    )
}

export default TextBanner;