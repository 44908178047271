import React from 'react';
import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import eventsBanner from '../../assets/banners/events.png';
import VagasList from '../../components/VagasList'; // Importando o novo componente
import './styles.css';

function Index() {
    return (
        <PageDefault>
            <SlideDefault
                h2='Vagas disponibilizadas pelo ITAI'
                text='O objetivo do Instituto é fomentar o envolvimento de pessoas, instituições e governos, promovendo um ecossistema de inovação para o desenvolvimento de ciência e tecnologia por meio de workshops, eventos, palestras, oficinas, apresentações culturais, capacitações e outros eventos sociais e corporativos.'
                image={eventsBanner}
            />
                {/* Renderizando o componente FileList no lugar da lista de eventos */}
                <VagasList />
        </PageDefault>
    );
}

export default Index;
