import React, { useEffect, useState } from 'react';
import './styles.css'; // Adicione os estilos necessários

const FileList = () => {
    const [data, setData] = useState({
        processosSeletivos: [] // Agora estamos lidando com múltiplos processos
    });

    useEffect(() => {
        fetch('/documents/vagas/files.json') // Certifique-se de que o caminho está correto
            .then((response) => response.json())
            .then((data) => setData(data))
            .catch((error) => console.error('Erro ao carregar o JSON', error));
    }, []);

    const getIcon = (iconType) => {
        switch (iconType) {
            case 'document':
                return <span role="img" aria-label="document">📄</span>; // Ícone de documento
            case 'checkmark':
                return <span role="img" aria-label="checkmark">✔️</span>; // Ícone de checkmark
            default:
                return null;
        }
    };

    const { processosSeletivos } = data;

    return (
        <div>
            {/* Renderizar todos os processos seletivos */}
            {processosSeletivos.length > 0 && processosSeletivos.map((processo, processIndex) => (
                <div key={processIndex} className="processo-container">
                    {/* Nome do Processo Seletivo */}
                    <div className="processo-seletivo-nome">
                        <h1>{processo.processoSeletivoNome}</h1>
                    </div>

                    {/* Seção de Vagas em Andamento */}
                    <div className="section">
                        <h2>Processos seletivos em andamento</h2>
                        {processo.vagas.filter(vaga => vaga.status === true).length > 0 ? (
                            processo.vagas.filter(vaga => vaga.status === true).map((vaga, vagaIndex) => (
                                <div key={vagaIndex} className="process">
                                    <h3>{vaga.processNumber} – {vaga.description}</h3>
                                    <ul>
                                        {vaga.files.map((file, fileIndex) => (
                                            <li key={fileIndex}>
                                                {getIcon(file.icon)}{' '}
                                                <a href={file.url} download>{file.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))
                        ) : (
                            <p>Nenhuma vaga em andamento no momento.</p>
                        )}
                    </div>

                    {/* Seção de Vagas Encerradas */}
                    <div className="section">
                        {processo.vagas.filter(vaga => vaga.status === false).length > 0 ? (
                            processo.vagas.filter(vaga => vaga.status === false).map((vaga, vagaIndex) => (
                                <><h2>Processos seletivos que estão encerrados</h2><div key={vagaIndex} className="process">
                                    <h3>{vaga.processNumber} – {vaga.description}</h3>
                                    <ul>
                                        {vaga.files.map((file, fileIndex) => (
                                            <li key={fileIndex}>
                                                {getIcon(file.icon)}{' '}
                                                <a href={file.url} download>{file.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div></>
                            ))
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FileList;
