export const maintainers = [
    {
        institution: 'ACIFI',
        holder: 'Dimas Bragagnolo',
        substitute: 'Edna Rubio'
    },
    {
        institution: 'FPTI',
        holder: 'Irineu Mario Colombo',
        substitute: 'Andrea  Pavei  Schmoeller'
    },
    {
        institution: 'ITAIPU BINACIONAL',
        holder: 'Leôncio Gonzalez',
        substitute: 'Luiz Claudio da Costa Barreto'
    },
    {
        institution: 'SEBRAE',
        holder: 'Augusto Stein',
        substitute: '-'
    },
    {
        institution: 'UNILA',
        holder: 'Daniel Nascimento',
        substitute: 'Edina Dorilda de Oliveira'
    },
    {
        institution: 'UNIOESTE',
        holder: 'Manoela Marli Jaqueira',
        substitute: 'Fernando José Martins'
    },
    {
        institution: 'BORKENHAGEN CONTABILIDADE',
        holder: 'Edvino Borkenhagen',
        substitute: 'Eunice Mariza Borkenhagen dos Santos'
    }
]

export const fiscal = [ 
    {
        institution: 'BORKENHAGEN CONTABILIDADE',
        holder: 'Eunice Mariza Borkenhagen dos Santos',
        substitute: 'Edvino Borkenhagen'
    },
    {
        institution: 'FPTI',
        holder: 'Ivete Terezinha Tenório Janh',
        substitute: '-'
    },
    {
        institution: 'ITAIPU',
        holder: 'Leôncio Gonzáles',
        substitute: 'Luiz Claudio da Costa Barreto'
    },
]

export const technical = [
    {
        institution: 'UNILA',
        formation: 'Dr. em Engenharia de Estruturas',
        holder: 'Aref Kalilo Lima Kzam',
    },
    {
        institution: 'IFPR',
        formation: 'Me. em Engenharia Elétrica e Computação',
        holder: 'Itamar Pena Nieradka',
    },
    {
        institution: 'UNIOESTE',
        formation: 'Dr. em Geografia',
        holder: 'Claudio Alexandre de Souza',
    },
    {
        institution: 'ITAIPU',
        formation: 'Me. em Engenharia Elétrica e Informática Industrial',
        holder: 'Galileu Godoi Terada',
    },
    {
        institution: 'ITAIPU',
        formation: 'Dr. em Engenharia Elétrica',
        holder: 'José Guilherme Rodrigues Filho',
    },
]