import alessandra from '../assets/associatesProfile/alessandraBussador.png';
import alvaro from '../assets/associatesProfile/alvaroMari.png';
import alysson from '../assets/associatesProfile/alyssonGuimaraes.png';
import ana from '../assets/associatesProfile/anaMari.png';
import anaCastro from '../assets/associatesProfile/anaCastro.png';
import angelo from '../assets/associatesProfile/angeloMari.png';
import barbara from '../assets/associatesProfile/Barbara.png';
import carlos from '../assets/associatesProfile/carlos.png';
import caroline from '../assets/associatesProfile/carolineFacanha.png';
import diegon from '../assets/associatesProfile/diegonNeves.png';
import edinardo from '../assets/associatesProfile/edinardo.png';
import eduardoDechechi from '../assets/associatesProfile/eduardoDechechi.png';
import eduardoPintor from '../assets/associatesProfile/eduardoPintor.png';
import eduardoTrindade from '../assets/associatesProfile/eduardoTrindade.png';
import elias from '../assets/associatesProfile/eliasGarcia.png';
import elida from '../assets/associatesProfile/elidaSantos.png';
import elisangela from '../assets/associatesProfile/elisangela.png';
import fabio from '../assets/associatesProfile/fabioPlut.png';
import henrique from '../assets/associatesProfile/henriqueBauermann.png';
import joao from '../assets/associatesProfile/joaoJuan.png';
import jose from '../assets/associatesProfile/joseCescon.png';
import joseSilva from '../assets/associatesProfile/joseSilva.png';
import liliane from '../assets/associatesProfile/liliane.png';
import lilianePrestes from '../assets/associatesProfile/lilianePrestes.png';
import lucas from '../assets/associatesProfile/lucasGarcia.png';
import miguel from '../assets/associatesProfile/miguelMatrakas.png';
import nelinho from '../assets/associatesProfile/nelinho.png';
import osmarina from '../assets/associatesProfile/osmarinaGarcia.png';
import ricardo from '../assets/associatesProfile/ricardo.png';
import tania from '../assets/associatesProfile/tania.png';
import wanessa from '../assets/associatesProfile/wanessaMiranda.png';
import william from '../assets/associatesProfile/william.png';
import karine from '../assets/associatesProfile/Karine.png';
import pablo from '../assets/associatesProfile/pablo.png';
import sandra from '../assets/associatesProfile/sandra.png';
import breno from '../assets/associatesProfile/breno.png';
import carlosUrzagasti from '../assets/associatesProfile/carlosUrzagasti.png';
import larissaBrandt from '../assets/associatesProfile/larissaBrandt.png';
import thiagoSantana from '../assets/associatesProfile/thiagoSantana.png';
import joaoFelipe from '../assets/associatesProfile/JoaoFelipe.png';
import luciana from '../assets/associatesProfile/lucianaFabriz.png';
import karl from '../assets/associatesProfile/karl.png';
import luiz from '../assets/associatesProfile/luizSilva.jpeg'
import mariangela from '../assets/associatesProfile/mariangela.jpeg'
import laura from '../assets/associatesProfile/laura.jpeg'
import daniel from '../assets/associatesProfile/daniel.jpeg'

export const ourAssociates = [
    {
        nome: 'Alessandra Bussador',
        titulo: 'Doutora em Energia e Sustentabilidade.',
        lattes: 'http://lattes.cnpq.br/4809436251402460',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Educação Tecnológica; Gestão Educacional; Cidades Inteligentes; e Destinos Turísticos Inteligentes.',
        email: 'alessandra.bussador@itai.org.br',
        graduacoes: 'Engenharia de Computação.',
        especializacoes: 'Educação a distância e tecnologias educacionais.',
        mestrados: 'Informática Aplicada.',
        doutorados: 'Energia e Sustentabilidade.',
        image: alessandra
    },
    {
        nome: 'Álvaro M. Junior',
        titulo: 'Doutor em Agronomia.',
        lattes: 'http://lattes.cnpq.br/7629898780105224',
        area: 'Meio Ambiente e Energias',
        atuacao: 'Engenharia Civil; Engenharia Ambiental; Segurança do Trabalho; Terraplenagem; Geoprocessamento; Elaboração de Projetos e Acompanhamento de Obras Civis e de Energias Renováveis; e Docência em Engenharia Civil e Ambiental.',
        email: 'alvaro.junior@itai.org.br',
        graduacoes: 'Engenharia Ambiental; Engenharia Civil.',
        especializacoes: 'Engenharia na Segurança do Trabalho.',
        mestrados: 'Energia na Agricultura.',
        doutorados: 'Agronomia (Irrigação e Drenagem).',
        image: alvaro,
    },
    {
        nome: 'Alysson G. de L. Guimarães',
        titulo: 'Graduado em Engenharia Mecânica.',
        lattes: 'http://lattes.cnpq.br/0908392211255182',
        area: 'Engenharia Mêcanica',
        atuacao: 'Elaboração e Gerenciamento de Projetos; Projetos de Peças e Estruturas Metálicas; Análise Estatística; Modelagem 3D; Simulação Computacional de Fluídos; Projetos Térmicos e Hidráulicos; e Impressão 3D.',
        email: 'alysson.guimaraes@itai.org.br',
        graduacoes: 'Engenharia Mecânica.',
        mestrados: 'Programa de Pós-Graduação em Tecnologia, Gestão e Sustentabilidade (em andamento).',
        image: alysson,
    },
    {
        nome: 'Ana Claudia C. Mari',
        titulo: 'Doutora em Agronomia.',
        lattes: 'http://lattes.cnpq.br/5307655102723446',
        area: 'Meio Ambiente e Energias',
        atuacao: 'Projetos e Pesquisas em Engenharia Ambiental, Irrigação e Drenagem; e Docência em Segurança do Trabalho.',
        email: 'ana.mari@itai.org.br',
        graduacoes: 'Engenharia Ambiental; Pedagogia (em andamento).',
        especializacoes: 'Engenharia de Segurança do Trabalho.',
        mestrados: 'Engenharia de Energia na Agricultura.',
        doutorados: 'Agronomia (Irrigação e Drenagem).',
        image: ana,
    },
    {
        nome: 'Ana Paula G. da S. Castro',
        titulo: 'Mestra em Energia e Sustentabilidade.',
        lattes: 'http://lattes.cnpq.br/7753082045890994',
        area: 'Meio Ambiente e Energias',
        atuacao: 'Energias Alternativas;  Ciência de Materiais; Desenvolvimento de Dispositivos para o Armazenamento de Energia; Pesquisa Científica com Inovação Tecnológica; e  Objetivos de Desenvolvimento Sustentáveis (ODS).',
        email: 'ana.castro@itai.org.br',
        graduacoes: 'Engenharia Ambiental.',
        especializacoes: 'Ciência e Engenharia de Materiais.',
        mestrados: 'Programa de Pós-graduação Interdisciplinar em Energia e Sustentabilidade - PPGIES.',
        image: anaCastro,
    },
    {
        nome: 'Angelo G. Mari',
        titulo: 'Doutor em Engenharia Agrícola.',
        lattes: 'http://lattes.cnpq.br/5940399467829427',
        area: 'Meio Ambiente e Energias',
        atuacao: 'Biogás; Bioenergia; Tratamento de Efluentes; e Engenharia Ambiental.',
        email: 'angelo.mari@itai.org.br',
        graduacoes: 'Engenharia Ambiental.',
        especializacoes: 'Agrimensura e Geoprocessamento; Gestão Ambiental em Municípios; Energias Renováveis com Ênfase em Biogás.',
        mestrados: 'Energia na Agricultura.',
        doutorados: 'Engenharia Agrícola.',
        image: angelo,
    },
    {
        nome: 'Bárbara F. C. Bauermann',
        titulo: 'Doutora em Desenvolvimento Regional e Agronegócio e Dra. em Agrária e Ambiente.',
        lattes: 'http://lattes.cnpq.br/9192137099508209',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Econômia; Análise Estatística; Logística; Agronegócio; Cidades Inteligentes; Destinos Turísticos Inteligentes; Desenvolvimento Regional; Revisão Gramatical e Ortográfica (Português); Tradução de Textos Português-Inglês.',
        email: 'barbara.bauerman@itai.org.br',
        graduacoes: 'Gestão do Agronegócio; Ciências Econômicas; Administração; Formação Pedagógica Letras-Inglês.',
        especializacoes: 'MBA em Engenharia de Software; MBA em Proteção de Dados e Compliance Digital.',
        mestrados: 'Desenvolvimento Regional.',
        doutorados: 'Desenvolvimento Regional e Agronegócio, Agrária e Ambiente.',
        image: barbara,
    },
    {
        nome: 'Breno C. Pinheiro',
        titulo: 'Doutor em Engenharia de Automação e Sistemas.',
        lattes: 'http://lattes.cnpq.br/4242825379957210',
        area: '',
        atuacao: 'Automação e Sistemas; Energia Renovável com Biogás e Biometano; Desenvolvimento de Projetos de P&D ANEEL com foco em Geração Distribuída; e Elaboração de Modelos de Negócios para o Setor Elétrico e Mobilidade Elétrica.',
        email: 'breno.pinheiro@itai.org.br',
        graduacoes: 'Engenharia Elétrica.',
        especializacoes: 'Gestão de Negócios do Setor Elétrico; e Ciência de Dados e Big Data.',
        mestrados: 'Engenharia de Automação e Sistemas.',
        doutorados: 'Engenharia de Automação e Sistemas.',
        image: breno,
    },
    {
        nome: 'Carlos A. Urzagasti',
        titulo: 'Graduado em Engenharia de Energia.',
        lattes: ' https://lattes.cnpq.br/6309371285872461',
        area: '',
        atuacao: 'Assistência Técnica em Projetos de Energia; Biogás e Biometano; Análise de Dados; Energias Renováveis; e Gestão de Projetos.',
        email: 'carlos.urzagasti@itai.org.br',
        graduacoes: 'Engenharia de Energia.',
        mestrados: 'Engenharia de Energia (em andamento).',
        image: carlosUrzagasti,
    },
    {
        nome: 'Carlos H. F. dos Santos',
        titulo: 'Doutor em Engenharia Elétrica.',
        lattes: ' http://lattes.cnpq.br/2021977611161895',
        area: 'Meio Ambiente e Energias',
        atuacao: 'Programação Estruturada e Orientada a Objeto; Controle Dinâmico Linear e Não-Linear; Controle de Processos Industriais, Digitais e Inteligentes; Inteligência Artificial e Computacional; Sistemas Dinâmicos a Eventos Discretos; Automação Industrial; Eletrônica de Potência; e Controle de Robôs.',
        email: 'carlos.santos@itai.org.br',
        graduacoes: 'Engenharia Elétrica.',
        mestrados: 'Engenharia Elétrica.',
        doutorados: 'Engenharia Elétrica.',
        image: carlos,
    },
    {
        nome: 'Caroline de L. E. Façanha',
        titulo: 'Mestra em Politicas Públicas e Desenvolvimento.',
        lattes: 'http://lattes.cnpq.br/6146012256175428',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Tutoria (EAD); Consultoria em Administração, Projetos, Secretariado, Logística; Gestão de Pessoas; e Relacionamento com Cliente.',
        email: 'caroline.facanha@itai.org.br',
        graduacoes: 'Administração.',
        especializacoes: 'Metodologia do Ensino na Educação Superior.',
        mestrados: 'Mestrado em Políticas Públicas e Desenvolvimento.',
        image: caroline,
    },
    {
        nome: "Daniel Costa Lima",
        titulo: "Mestre em Operações Militares.",
        lattes: "http://lattes.cnpq.br/8781625851196371",
        area: "Tecnologia da Informação",
        atuacao: " Tecnologia da Informação e Comunicações (TIC); Cidades Inteligentes; Big Data; Ciência de Dados; Inteligência Artificial; Análise de Processos de Negócio; Internet das Coisas (IoT); Transformação Digital; Automação de Processos da Cadeia de Suprimento (Supply Chain); Segurança da Informação e Comunicações; e Análise e Projeto de Sistemas.",
        email: "daniel.costa@itai.org.br",
        graduacoes: "Bacharel em Ciências Militares;",
        especializacoes: "Desenvolvimento de Aplicações WWW;",
        mestrados: "Operações Militares",
        image: daniel,
    },
    {
        nome: 'Diegon R. Neves',
        titulo: 'Graduado em Turismo.',
        lattes: 'http://lattes.cnpq.br/2292557203542002',
        area: '',
        atuacao: 'Gestão de Projetos Sociais e Educionais; Turismo; e Captação de Recursos.',
        email: 'diegon.neves@itai.org.br',
        graduacoes: 'Bacharelado em Turismo',
        image: diegon,
    },
    {
        nome: 'Edinardo A. B. de Aguiar',
        titulo: 'Especialista em gestão de Pequenos Negócios.',
        lattes: 'http://lattes.cnpq.br/5753993768179654',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Gestão Pública, Elaboração, Prospecção e Gerenciamento de Projetos; Turismo; Cidades Inteligentes; e Inovação.',
        email: 'edinardo.aguiar@itai.org.br',
        graduacoes: 'Administração.',
        especializacoes: 'Gestão de Pequenos Negócios.',
        image: edinardo,
    },
    {
        nome: 'Eduardo C. Dechechi',
        titulo: 'Doutor em Engenharia Química.',
        lattes: 'http://lattes.cnpq.br/0232503428559756',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Gestão de Inovação em Ambientes de Tecnologia; Energia; e Gestão Educacional para Desenvolvimento de Competências Empresariais e de Engenharia.',
        email: 'eduardo.dechechi@itai.org.br',
        graduacoes: 'Engenharia Química.',
        especializacoes: 'Gestão Estratégica da Inovação Tecnológica no Setor de Energia Elétrica; Programa Internacional de Inovação Tecnológica em Empresas; Gestão de Projetos de Desenvolvimento Científico e Tecnológico.',
        mestrados: 'Engenharia Química.',
        doutorados: 'Engenharia Química.',
        image: eduardoDechechi,
    },
    {
        nome: 'Eduardo de Pintor',
        titulo: 'Doutor em Desenvolvimento Regional.',
        lattes: 'http://lattes.cnpq.br/2420143250642690',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Metodologias da Administração Pública; Viabilidade Econômica; Prospecção de Demanda; Levantamento de Custos; Pesquisa de Mercado; Ensino em Pós-Graduação; e Pesquisa e Desenvolvimento.',
        email: 'eduardo.pintor@itai.org.br',
        graduacoes: 'Ciências Econômicas.',
        mestrados: 'Desenvolvimento Regional e Agronegócio.',
        doutorados: 'Desenvolvimento Regional e Agronegócio.',
        image: eduardoPintor,
    },
    {
        nome: 'Eduardo M. Trindade',
        titulo: 'Doutor em Ciências (Química Orgânica).',
        lattes: 'http://lattes.cnpq.br/5939795796788055',
        area: 'Meio Ambiente e Energias',
        atuacao: 'Biogás e Bioenergia; Capacitação em CT+I; Projetos ANEEL; Consultoria em Sistemas Isolantes para o Setor Elétrico; ISO 17025 em Laboratórios; e Elaboração de Projetos.',
        email: 'eduardo.trindade@itai.org.br',
        graduacoes: 'Bacharelado em Química; Bacharelado em Ciências Econômicas.',
        especializacoes: 'Gerenciamento De Projetos.',
        mestrados: 'Química.',
        doutorados: 'Química.',
        image: eduardoTrindade,
    },
    {
        nome: 'Elias Garcia',
        titulo: 'Doutor em Ciências Empresariais e Dr. em Letras.',
        lattes: 'http://lattes.cnpq.br/7569267700528150',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Gestão Empresarial, Administrativa e Fiscal; Tecnologia e Inovação; Controladoria e Finanças;  Educação Corporativa; Agronegócios; Projetos Acadêmicos; Gestão Pública; e  Auditoria e Perícia Contábil.',
        email: 'elias.garcia@itai.org.br ',
        graduacoes: 'Ciências Contábeis.',
        especializacoes: 'Contabilidade Gerencial; Auditoria; Advanced Leadership Seminar.',
        mestrados: 'Contabilidade.',
        doutorados: 'Ciências Empresariais, Letras.',
        image: elias,
    },
    {
        nome: 'Elida S. dos Santos',
        titulo: 'Mestra em Tecnologias, Gestão e Sustentabilidade.',
        lattes: 'http://lattes.cnpq.br/1784392899260170',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Gestão de Projetos; Consultoria Administrativa;  Integração Universidade-Empresa e Inovação.',
        email: 'elida.santos@itai.org.br',
        graduacoes: 'Administração com habilitação em Marketing.',
        especializacoes: 'Especialização em MBA Elaboração, Avaliação e Gerenciamento de Projetos.',
        mestrados: 'Mestrado profissional em Tecnologias, Gestão e Sustentabilidade.',
        image: elida,
    },
    {
        nome: 'Elisangela F. Carolino',
        titulo: 'Doutora em Agronomia.',
        lattes: 'http://lattes.cnpq.br/1510954275886540',
        area: 'Meio Ambiente e Energias',
        atuacao: 'Educação; Docência para Ensino Superior e Pós-Graduação;  Capacitação nas áreas de Educação e Sustentabilidade, Gestão Ambiental, Gestão de Resíduos Sólidos Urbanos e Educação Ambiental.',
        email: 'elisangela.carolino@itai.org.br',
        graduacoes: 'Licenciatura Plena em Geografia.',
        mestrados: 'Agronomia.',
        doutorados: 'Agronomia.',
        image: elisangela,
    },
    {
        nome: 'Fabio P. Fernandes',
        titulo: 'Doutor em Química.',
        lattes: 'http://lattes.cnpq.br/4836435433244717',
        area: 'Meio Ambiente e Energias',
        atuacao: 'P&D em Baterias e Economia do Hidrogênio; Eletroquímica Aplicada e Novos Materiais; Inovação com Inteligência de Mercado e Propriedade Intelectual; e Projetos com Órgãos de Financiamento Públicos e Privados.',
        email: 'fabio.fernandes@itai.org.br',
        graduacoes: 'Química.',
        mestrados: 'Química.',
        doutorados: 'Química.',
        image: fabio,
    },
    {
        nome: 'Henrique B. Bauermann',
        titulo: 'Mestre em Desenvolvimento Rural Sustentável.',
        lattes: 'http://lattes.cnpq.br/7601234006331284',
        area: 'Tecnologia da Informação',
        atuacao: 'Inteligência Artificial; Visão Computacional, Ciência de Dados, Business Intelligence; e Desenvolvimento Python.',
        email: 'henrique.bauermann@itai.org.br',
        graduacoes: 'Engenharia de Produção.',
        especializacoes: 'Gestão da Tecnologia de Informação; Engenharia de Software; Engenharia de Segurança no Trabalho.',
        mestrados: 'Desenvolvimento Rural Sustentável.',
        image: henrique,
    },
    {
        nome: 'João D. dos S. Mendonça',
        titulo: 'Mestre em Tecnologias, Gestão e Sustentabilidade.',
        lattes: 'http://lattes.cnpq.br/9707382430914485',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Empreendedorismo Educacional e Empresarial; Design Corporativo; Modelagem Empresarial;  Modelagem de Relação Intercultural; e Inovação e Sustentabilidade.',
        email: 'joao.mendonca@itai.org.br',
        graduacoes: 'Teologia.',
        especializacoes: 'Teologia; Língua Espanhola e Novas Tecnologias.',
        mestrados: 'Tecnologias, Gestão e Sustentabilidade.',
        image: joao,
    },
    {
        nome: 'João A. Felipe',
        titulo: 'Especialista em Direito Empresarial e Tributário',
        lattes: 'https://lattes.cnpq.br/4501305445961353',
        area: '',
        atuacao: 'Direito, com foco em Empresarial e Tributário. Gerenciamento de Projetos e Captação de Recursos.',
        email: 'joao.felipe@itai.org.br',
        graduacoes: 'Direito; e Tecnólogo em Processos Gerenciais;',
        especializacoes: 'Direito Empresarial e Tributário.',
        image: joaoFelipe,
    },
    {
        nome: 'José A. Cescon',
        titulo: 'Doutor em Ciências Contábeis.',
        lattes: 'http://lattes.cnpq.br/1531423734016048',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Gestão e Sistemas de Custos/Custeio;  Auditoria Contábil; e Planos de Negócio.',
        email: 'jose.cescon@itai.org.br',
        graduacoes: 'Ciências Contábeis.',
        especializacoes: 'Auditoria e Perícia Contábil.',
        mestrados: 'Ciências Contábeis – Auditoria e Controladoria.',
        doutorados: 'Ciências Contábeis.',
        image: jose,
    },
    
    {
        nome: 'Jose R. P. da Silva',
        titulo: 'Doutor em Ciências e Engenharia Elétrica.',
        lattes: 'http://lattes.cnpq.br/9257650962986363',
        area: 'Meio Ambiente e Energias',
        atuacao: 'Projetos ANEEL; Sistema Elétrico de Potência; Sistemas de Controle, Transmissão e Dinâmica da Geração (Simulações Digitais); e Obras de Geração e Comissionamentos e de Manutenção de Fontes Convencionais e Alternativas de Energia Elétrica.',
        email: 'jose.silva@itai.org.br',
        graduacoes: 'Engenharia Elétrica.',
        especializacoes: 'Economia de Empresas; Controle de Sistemas Elétricos; Direção de Empresas.',
        mestrados: 'Engenharia Elétrica.',
        doutorados: 'Ciências e Engenharia Elétrica.',
        image: joseSilva,
    },
    {
        nome: 'Karine B. dos Santos',
        titulo: 'Doutora em Psicologia Clínica e Cultura.',
        lattes: 'http://lattes.cnpq.br/6622850179815923 ',
        area: 'Psicologia',
        atuacao: 'Psicologia Social, do Trabalho e das Organizações; Consultoria e Capacitações em Prevenção do Assédio no Trabalho; Psicologia Clínica; Prevenção e Promoção de Saúde Mental; Melhoria da Qualidade de Vida e Bem-Estar Psicológico; e Prevenção à Violência.',
        email: 'karine.brito@itai.org.br',
        graduacoes: 'Psicologia; Comunicação Social com habilitação em Publicidade e Propaganda.',
        mestrados: 'Psicologia Social, do Trabalho e das Organizações.',
        doutorados: 'Psicologia Clínica e Cultura.',
        image: karine,
    },
    {
        nome: 'Karl Stoeckl',
        titulo: 'Mestre em Administração.',
        lattes: 'http://lattes.cnpq.br/3273957773303225',
        area: '',
        atuacao: 'Comércio Exterior; Logística; e Administração Pública.',
        email: 'karl.stoeckl@itai.org.br',
        graduacoes: 'Administração',
        especializacoes: 'Administração Estratégica.',
        mestrados: 'Administração.',
        doutorados: 'Administração (em andamento).',
        image: karl,
    },
    {
        nome: 'Larissa S. Brandt',
        titulo: 'Especialista em Cadeia Produtiva do Biogás.',
        lattes: 'http://lattes.cnpq.br/6314637956415309',
        area: '',
        atuacao: 'Energias Renováveis, Biogás e Geração Distribuída de Energia Elétrica; Biometano e Gás Natural; Aproveitamento de Biogás, Biometano e Gás Natural em Sistemas de Geração de Energia Térmica; e Monitoramento e Controle de Processos Industriais e de Elaboração de Propostas Comerciais e Prospecção de Negócios.',
        email: 'larissa.brandt@itai.org.br',
        graduacoes: 'Engenharia Mecânica.',
        especializacoes: 'Cadeia Produtiva do Biogás',
        image: larissaBrandt,
    },
    {
        nome: 'Laura Escudeiro de Vasconcelos',
        titulo: 'Mestra em Relações Internacionais.',
        lattes: 'http://lattes.cnpq.br/5791951272697637',
        area: '',
        atuacao: 'Áreas de atuação: Cooperação Internacional; Gestão de Projetos; Criatividade, Empreendedorismo e Inovação; Organização de Eventos; Gerenciamento de Stakeholders; Objetivos do Desenvolvimento Sustentável; Relações Internacionais e Governamentais; Gestão de Pessoas; e Design Thinking.',
        email: 'laura.escudeiro@itai.org.br',
        graduacoes: ' Ciência Política;',
        especializacoes: 'Gestão de Projetos;',
        mestrados: 'Relações Internacionais.',
        image: laura,
    },
    {
        nome: 'Liliane M. M. de Souza',
        titulo: 'Doutora em Físico Química.',
        lattes: 'http://lattes.cnpq.br/6454546086357595',
        area: 'Pedagogia',
        atuacao: 'Projetos de P&D+I em Armazenadores de energia; Projeto Industrial na área de Pilhas e Baterias;  e  Consultoria para Instalação e Capacitação da Norma ABNT NBR ISO/IEC 17025.',
        email: 'liliane.souza@itai.org.br',
        graduacoes: 'Química.',
        especializacoes: 'Docência na Educação Profissional de Nível Técnico.',
        mestrados: 'Ciência e Engenharia de Materiais com ênfase em metalurgia.',
        doutorados: 'Físico Química.',
        image: liliane,
    },
    {
        nome: 'Liliane P. de Oliveira',
        titulo: 'Graduada em Pedagogia.',
        lattes: 'http://lattes.cnpq.br/7798058053563550',
        area: 'Pedagogia',
        atuacao: 'Pedagogia e Gestão Educacional; Educação Tecnológica e Inclusiva; Educação à Distância - EaD;  Elaboração e Gestão de Projetos na área Educacional; Gestão Administrativa.',
        email: 'liliane.oliveira@itai.org.br',
        graduacoes: 'Pedagogia; Administração Pública.',
        especializacoes: 'Pós-Graduação Lato Sensu em Ensino de Ciências; Pós-Graduação Lato Sensu em Educação Profissional e Tecnológica; Pós-Graduação Lato Sensu em Educação Especial e Inclusiva; Pós-Graduação Lato Sensu em Design Instrucional.',
        image: lilianePrestes,
    },
    {
        nome: 'Lucas H. Garcia',
        titulo: 'Graduado em Engenharia Civil.',
        lattes: 'http://lattes.cnpq.br/7955770437894496',
        area: 'Meio Ambiente e Energias',
        atuacao: 'Sistemas de informação geográfica (GIS); Análises Espaciais; Gestão do Território; Pilotagem de Drones; e Hidrologia.',
        email: 'lucas.garcia@itai.org.br',
        graduacoes: 'Engenharia Civil.',
        image: lucas,
    },
    {
        nome: 'Luciana Aparecida Fabriz',
        titulo: 'Doutora em Enfermagem em Saúde Pública.',
        lattes: 'http://lattes.cnpq.br/7313249390163319',
        area: '',
        atuacao: 'Pesquisa e Docência; Gestão dos Serviços de Saúde e Políticas Públicas de Saúde; e Elaboração de Projetos e Assessoria para Captação de Recursos.',
        email: 'luciana.fabriz@itai.org.br',
        graduacoes: 'Enfermagem.',
        especializacoes: 'Centro Cirúrgico e centro de Material.',
        mestrados: 'Enfermagem.',
        doutorados: 'Enfermagem em Saúde Pública.',
        image: luciana,
    },
    {
        nome: 'Luiz Henrique Dias da Silva ',
        titulo: 'Especialista em Ensino de Matemática; e Gestão Aeroportuária.',
        lattes: 'http://lattes.cnpq.br/8979281654032297',
        area: '',
        atuacao: 'Políticas Públicas; Representações Políticas; Relações Governamentais; Projetos de Participação Popular; Planejamento Estratégico; e Treinamentos, Palestras e Capacitações.',
        email: 'luiz.silva@itai.org.br',
        graduacoes: 'Gestão de Políticas Públicas; e Arquitetura e Urbanismo (em andamento);',
        especializacoes: 'Ensino de Matemática; e Gestão Aeroportuária;',
        mestrados: 'História (em andamento).',
        image: luiz,
    },
    {
        nome: 'Mariangela Vieira da Silva Luckmann',
        titulo: 'Esp. em Gestão de Recursos Humanos; e Práticas Terapêuticas em Perdas e Lutos.',
        lattes: ' http://lattes.cnpq.br/7077742383077828 ',
        area: '',
        atuacao: 'Gestão de Pessoas; Skills; Educação Corporativa; Voluntariado; Projetos Sustentáveis de Impacto Positivo; Liderança; Networking de Impacto; Gestão de Conflitos; Novas Economias e Culturas Organizacionais: Sistema B; ESG; Gestão de Resíduos; e Projetos em Saúde Mental.',
        email: 'mariangela.luckmman@itai.org.br',
        graduacoes: 'Administração de Empresas; e Psicologia Clínico-Social;',
        especializacoes: 'Gestão de Recursos Humanos; e Práticas Terapêuticas em Perdas e Lutos.',
        image: mariangela,
    },
    {
        nome: 'Miguel D. Matrakas',
        titulo: 'Doutor em Métodos Numéricos em Engenharia.',
        lattes: 'http://lattes.cnpq.br/4512281451584488',
        area: 'Tecnologia da Informação',
        atuacao: 'Desenvolvimento de Sistemas;  Processamento de Imagens;  Métodos Numéricos;  Indicadores de Desempenho; e Cidades Inteligentes.',
        email: 'miguel.matrakas@itai.org.br',
        graduacoes: 'Engenharia de Computação.',
        mestrados: 'Informática Aplicada.',
        doutorados: 'Métodos Numéricos em Engenharia.',
        image: miguel,
    },
    {
        nome: 'Nelinho D. Graef',
        titulo: 'Doutor em Desenvolvimento Regional e Agronegócio.',
        lattes: 'http://lattes.cnpq.br/2825090065027475',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Consultoria em Administração; Gerenciamento e Elaboração de Projetos; Gestão da Inovação e Inovação Aberta.',
        email: 'nelinho.graef@itai.org.br',
        graduacoes: 'Administração.',
        mestrados: 'Desenvolvimento Regional e Agronegócio.',
        doutorados: 'Desenvolvimento Regional e Agronegócio.',
        image: nelinho,
    },
    {
        nome: 'Osmarina P. G. Garcia',
        titulo: 'Mestra em Gestão e Desenvolvimento Regional.',
        lattes: 'http://lattes.cnpq.br/0862157573680879',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Gestão do Conhecimento; Finanças e Empreendedorismo; Elaboração de Projetos em Educação Corporativa; Agronegócios; Auditoria e Perícia Financeira.',
        email: 'osmarina.garcia@itai.org.br',
        graduacoes: 'Ciências Contábeis.',
        especializacoes: 'Contabilidade e Gestão Estratégica; Auditoria e Perícia Contábil.',
        mestrados: 'Gestão e Desenvolvimento Regional.',
        image: osmarina,
    },
    {
        nome: 'Pablo E. K. Lohse',
        titulo: 'Especialista em Gestão Financeira.',
        lattes: '',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Gestão Financeira; Consultória Estratégica;   Empreendedorismo; Coaching para vendas, marketing e publicidade; e Comunicação e Gerenciamento de Conflitos.',
        email: 'pablo.kovacs@itai.org.br',
        graduacoes: 'Licenciado em Contabilidade.',
        especializacoes: 'Gestão financeira.',
        image: pablo,
    },
    {
        nome: 'Ricardo L. Schaefer',
        titulo: 'Doutor em Engenharia Mecânica.',
        lattes: 'http://lattes.cnpq.br/8380193777003660',
        area: 'Engenharia Mêcanica',
        atuacao: 'Ciências Exatas.',
        email: 'ricardo.shaefer@itai.org.br',
        graduacoes: 'Engenharia Mecânica.',
        mestrados: 'Engenharia Mecânica.',
        doutorados: 'Engenharia Mecânica.',
        image: ricardo,
    },
    {
        nome: 'Sandra C. Kleinschmitt',
        titulo: 'Doutora em Sociologia.',
        lattes: 'https://lattes.cnpq.br/7586034788475372',
        area: '',
        atuacao: 'Elaboração e Gestão de Projetos Culturais, Esportivos e Sociais; Captação de Recursos; Consultoria e Assessoria para Projetos Culturais, Esportivos e Sociais; Palestras e Treinamentos; Pesquisa Social de Opinião Pública – Quantitativas e Qualitativas.',
        email: 'sandra.kleinschmitt@itai.org.br',
        graduacoes: 'Ciências Sociais.',
        especializacoes: 'Segurança Pública, Cidadania e Direitos.',
        mestrados: 'Ciências Sociais, Desenvolvimento Regional e Agronegócio.',
        doutorados: 'Sociologia.',
        image: sandra,
    },
    {
        nome: 'Tânia J. R. Mari',
        titulo: 'Graduada em Turismo.',
        lattes: 'http://lattes.cnpq.br/8870210243298554',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Elaboração e Gestão de Projetos  Sociais e Educacionais; Assessoria de Relações Institucionais; e Gestão de Processos Administrativos.',
        email: 'tania.mari@itai.org.br',
        graduacoes: 'Turismo.',
        image: tania,
    },
    {
        nome: 'Thiago O. P. Sant’ana',
        titulo: 'Especialista em Tecnologias da Cadeia Produtiva do Biogás.',
        lattes: 'http://lattes.cnpq.br/8457500650821951',
        area: '',
        atuacao: 'Projetos Técnicos de Biogás e Biometano; Comercialização de Energia Elétrica; Regulação ANEEL; Regulação ANP; Estudo de Viabilidade Econômico-Financeira; e Assessoria de Captação Financeira/Financiamento Bancário para Projetos de Energia Renovável.',
        email: 'thiago.santana@itai.org.br',
        graduacoes: 'Engenharia Elétrica.',
        especializacoes: 'Tecnologias Da Cadeia Produtiva Do Biogás; Comercialização de Energia Elétrica (em andamento).',
        image: thiagoSantana,
    },
    {
        nome: 'Wanessa L. de O. Miranda',
        titulo: 'Mestra em Administração.',
        lattes: 'http://lattes.cnpq.br/5262939336507927',
        area: 'Gestão e Elaboração de Projetos',
        atuacao: 'Consultoria em Engenharia Civil e Administração; Elaboração de Conteúdos para Universidades/Faculdades; Orientação de Trabalhos de Conclusão de Curso; e Projetos Ágeis.',
        email: 'wanessa.miranda@itai.org.br',
        graduacoes: 'Engenharia Civil.',
        especializacoes: 'Gestão Empreendedora.',
        mestrados: 'Administração.',
        image: wanessa,
    },
    {
        nome: 'Willian C. Wurmeister',
        titulo: 'Graduado em Sistemas de Informação.',
        lattes: 'https://lattes.cnpq.br/6563422473646190',
        area: 'Tecnologia da Informação',
        atuacao: 'UX/UI Designer (Designer de Experiência do Usuário e Designer de Interfaces e Interações);  Programador Front-End; Design; e Análise e Desenvolvimento de Sistemas.',
        email: 'willian.wurmeister@itai.org.br',
        graduacoes: 'Sistemas de Informação.',
        image: william,
    },

]