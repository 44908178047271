import { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../TabPanel/TabPanel';
import InternForm from '../InternForm/InternForm';
import CompanyForm from '../CompanyForm/CompanyForm';
import InstitutionForm from '../InstitutionForm/InstitutionForm';
import ListComponent from '../ListComponent';
import { company, institution, contacts } from '../../data/internshipData';
import './InternshipSwitch.css'

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function InternshipSwitch() {
    const [value, setValue] = useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <div className='root'>
            <div className='vertical-tabs'>
                <Tabs
                    orientation="horizontal"
                    value={value}
                    onChange={handleChange}
                    className='tabs'
                    indicatorColor="primary"
                >
                    <Tab label="ESTUDANTE" {...a11yProps(0)} className='tab'/>
                    <Tab label="EMPRESA" {...a11yProps(1)} className='tab'/>
                    <Tab label="INSTITUIÇÃO DE ENSINO" {...a11yProps(2)} className='tab'/>
                </Tabs>
            </div>
            
            <TabPanel value={value} index={0} className='tab-panel-internship'>
                <h3 className='internship-text'>Estudante, realize sua inscrição agora mesmo e impulsione sua carreira profissional.</h3>
                <InternForm />
            </TabPanel>

            <TabPanel value={value} index={1} className='tab-panel-internship'>
                <h3 className='internship-text'>O Programa de Estágios oferece a sua empresa diversos serviços que facilitarão a realização de estágios com qualidade:</h3>
                <ListComponent arrayList={company} nameInput='container' text='text'/>
                <ListComponent arrayList={contacts} nameInput='container' text='text'/>
                <h3 className='internship-text'>Faça seu cadastro prévio abaixo:</h3>
                <CompanyForm />
            </TabPanel>

            <TabPanel value={value} index={2} className='tab-panel-internship'>
                <h3 className='internship-text'>O Programa de Estágios oferece a sua Instituição de Ensino diversos benefícios que facilitarão a realização de estágios com qualidade:</h3>
                <ListComponent arrayList={institution} nameInput='container' text='text'/>
                <ListComponent arrayList={contacts} nameInput='container' text='text'/>
                <h3 className='internship-text'>Faça seu cadastro prévio abaixo:</h3>
                <InstitutionForm />
            </TabPanel>

        </div>
    )
}

export default InternshipSwitch