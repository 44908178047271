import {GrClose} from 'react-icons/gr';
import './styles.css';

function index(props) {
    const {images, title, text, setOpen, copyright} = props;

    return (
        <>
            <div className="case-modal-background" onClick={() => setOpen(false)} />

            <div className="case-modal">
                <GrClose className='close-icon' onClick={() => setOpen(false)} />
                <div className='images-section'>
                    {images.map((image) =>
                        <img src={image} alt="Imagem do projeto" />
                    )}
                </div>
                {copyright && <p className='copyright'>{copyright}</p>}
                <h2>{title}</h2>
                {text}
            </div>
        </>
    )
}

export default index;