import energiaIMG from '../assets/atuacao/energia.webp';
import industriaIMG from '../assets/atuacao/industria.webp';
import agroIMG from '../assets/atuacao/agro.webp';
import educacaoIMG from '../assets/atuacao/educacao.webp';
import embarcadosIMG from '../assets/atuacao/embarcado.webp';
import gestaoIMG from '../assets/atuacao/gestao.webp';
import recursosIMG from '../assets/atuacao/recursos.webp';
import turismoIMG from '../assets/atuacao/turismo.webp';
import demandasIMG from '../assets/atuacao/demandas.webp';
import desenvolvimentoIMG from '../assets/atuacao/desenvolvimento.webp';

export const smallSquare = [
    {
        image : energiaIMG,
		legend : "ENERGIAS ALTERNATIVAS",
		content : "Concepção, desenvolvimento e implementação de projetos relacionados a prospecção, geração, transmissão e distribuição de energia solar, eólica, hidráulica, biomassa e outras."
    },
    {
        image : industriaIMG,
        legend : "Indústria X.0",
        content : "Planejamento, logística, controle e melhoria de processos, gestão da qualidade, análise de risco, manufatura digital, simulação de cenários de processos e de negócios e gestão da tecnologia, visando a implantação de processos ágeis, eficientes e produtivos nas organizações. Desse modo, o Instituto desenvolve projetos em diferentes áreas, como automação, cibersegurança, gerenciamento de dados, entre outras."
    },
    {
        image : agroIMG,
        legend : "Agronegócio",
        content : "Provimento de soluções de abastecimento energético para instalações de agronegócio (geração distribuída); soluções de monitoramento de instalações, equipamentos e máquinas; estudos e elaboração de projetos para geração distribuída, entre outros."
    },
    {
        image : embarcadosIMG,
        legend : "Sistemas Embarcados",
        content : "Concepção, desenvolvimento e implementação de softwares embarcados para diferentes segmentos, incluindo soluções baseadas em plataformas abertas e conectividade; concepção, levantamento de requisitos e alternativas, especificação de componentes, elaboração dos esquemáticos eletrônicos, elaboração do layout, prototipagem, realização de testes, verificação e validação para áreas como automação e controle de máquinas e processos, eletrônica embarcada para dispositivos eletromédicos, condicionamento e aquisição de dados, telemetria e eletrônicos de consumo."
    },
    {
        image : educacaoIMG,
        legend : "Educação Tecnológica",
        content : "Desenvolvimento de atividades, projetos e soluções que estimulem a formação de competências em ciência, tecnologia e inovação para incentivar a capacidade empreendedora e a produção científico-tecnológica; propiciem a compreensão e a avaliação dos impactos sociais, econômicos e ambientais resultantes da produção, gestão e incorporação de novas tecnologias; estimulem o desenvolvimento de soluções técnicas."
    },
    {
        image : gestaoIMG,
        legend : "Gestão Laboratorial",
        content : "Gestão de ambientes laboratoriais de pesquisa de universidades públicas, por meio de atividades de planejamento e execução de práticas e procedimentos laboratoriais, capacitação de profissionais de acordo com padrões nacionais e internacionais; dimensionamento do laboratório de acordo com a demanda e público alvo; gestão de pessoas para o melhor desenvolvimento técnico do laboratório; melhoria da qualidade de produtos e serviços ofertados pelo laboratório; e, comercialização de soluções tecnológicas (prestação de serviços diversos)."
    },
    {
        image : turismoIMG,
        legend : "Tecnologia para o Turismo",
        content : "Desenvolvimento de pesquisas e identificação de novas oportunidades e plataformas tecnológicas, como realidade aumentada e softwares de comunicação; análise de viabilidade de negócios turísticos; planejamento e desenvolvimento das atividades turísticas nos setores público e privado, entre outros."
    },
    {
        image : recursosIMG,
        legend : "Captação de Recursos",
        content : "Desenvolvimento de estratégias e implementação de processos e ferramentas para a captação de recursos, capacitação de profissionais no tema, apoio à elaboração e submissão de projetos de P&D&I junto aos órgãos de fomento nacionais e internacionais, e avaliação de projetos de P&D&I já elaborados."
    }
]

export const bigSquare = [
    {
        image : demandasIMG,
        legend : "Prospecção de Demandas",
        content : "- Identificação de oportunidades a serem realizadas em conjunto entre instituições; planejamento, acompanhamento, avaliação e divulgação de resultados relativos às atividades e projetos desenvolvidos."
    },
    {
        image : desenvolvimentoIMG,
        legend : "Cadeias Produtivas",
        content : "Desenvolvimento de projetos conjuntos com as câmaras técnicas de Programas de Desenvolvimento Regional e cadeias produtivas propulsivas; realização de estudos/diagnósticos setoriais para desenvolvimento de inovações; articulação de políticas públicas estruturadas com as governanças regionais para facilitar a geração e a disseminação de informações sobre a realidade social e econômica; estudos de viabilidade técnica, econômica e ambiental, como suporte à implantação de ações voltadas à estruturação do desenvolvimento regional; projetos de qualificação ou requalificação profissional; projetos de modernização tecnológica das cadeias produtivas das respectivas regiões, entre outros."
    }
]