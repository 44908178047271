import React from 'react';
import './styles.css';

import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import DownloadFile from '../../components/DownloadFile';

import campanha from '../../assets/campanha.jpg'
import pix from '../../assets/pix.png'

const Campaign = () => {
    return (
        <PageDefault>
            <SlideDefault
                h2="Campanha Todos Pela Reconstrução da Unioeste Foz"
                textTwo="Em Outubro deste ano devido à um forte vendaval seguidos por chuvas intensas que ocorreram na região da Vila C em Foz do Iguaçu, a estrutura física da UNIOESTE foi severamente danificada, houve o destelhamento de quase todos os prédios que gerou danos à equipamentos, materiais e documentos da instituição."
                buttonRenderization={false}
                image={campanha}
                titleButton="Nota Pública"
            />
            <div className="content-text">
                <h2>
                    Com toda essa situação a comunidade está se mobilizando para que a reconstrução da UNIOESTE seja realizada prontamente a tempo do retorno às aulas.
                    Para isso, é necessário o maior envolvimento possível da sociedade como um todo.
                    O ITAI é a instituição responsável pela arrecadação dos valores que serão utilizados para compra de materiais, insumos e mão de obra.
                    Confira a nota pública desta ação.
                </h2>
                <DownloadFile documentURL="/documents/NotaPublicaITAI.pdf" imgRenderization={false} title="Nota Pública" />
                <div className="content-bank">
                    <h1>Faça sua doação</h1>
                    <div className="content-transfer">
                        <div>
                            <h3>PIX: unioeste@itai.org.br</h3>
                            <img className="img-responsive" src={pix} alt="qrcode"></img>
                        </div>
                        <div>
                            <h3>Depósito, DOC, transferência:</h3>
                            <p>Banco Sicoob (756)</p>
                            <p>Agência 4343</p>
                            <p>Conta: 62.164-1</p>
                            <p>CNPJ 01.573.107/0001-91</p>
                        </div>
                    </div>
                </div>
                <h2>Participe também dessa campanha</h2>
                <h4>#TodospelareconstruçãodaUnioeste!!</h4>
            </div>
        </PageDefault>
    );
}

export default Campaign;