// import { useState, useEffect } from 'react';
// import axios from 'axios';
// import { IoIosArrowDown } from 'react-icons/io';
import AssociateCard from '../../components/AssociateCard/AssociateCard';
import { ourAssociates } from '../../data/ourAssociates';
import './AssociateSearch.css';

function AssociateSearch() {
    // const [associates, setAssociates] = useState([]);
    // const [area, setArea] = useState('');
    // const [optionTitle, setOptionsTitle] = useState('Filtrar por Área');
    // const [showOptions, setShowOptions] = useState(false);
    // const baseURL = 'http://localhost:5000/associates/';

    // useEffect(() => {
    //     axios.get(baseURL + area)
    //         .then(res => setAssociates(res.data))
    //         .catch(err => console.log(`Ocorreu um erro ao consultar a API ${err}`));
    // }, [area])

    return (
        <>
            <h2 className='associates-title'><strong>Nossos Associados</strong></h2>
            {/* <div className='filter' onClick={() => setShowOptions(!showOptions)}>
                <p>{ optionTitle }</p>
                <IoIosArrowDown className='icon'/>
                {showOptions && <ul>
                    <li onClick={() => {
                        setArea('')
                        setOptionsTitle('Filtrar por Área')
                    }}>
                        Todos
                    </li>
                    <li onClick={() => {
                        setArea('gestao-elaboracao-projetos') 
                        setOptionsTitle('Gestão e Elaboração de Projetos')
                    }}>
                        Gestão e Elaboração de Projetos
                    </li>
                    <li onClick={() => {
                        setArea('meio-ambiente-energias')
                        setOptionsTitle('Meio Ambiente e Energias')
                    }}>
                        Meio Ambiente e Energias
                    </li>
                    <li onClick={() => {
                        setArea('engenharia-mecanica')
                        setOptionsTitle('Engenharia Mecanica')
                    }}>
                        Engenharia Mecânica
                    </li>
                    <li onClick={() => {
                        setArea('tecnologia-informacao')
                        setOptionsTitle('Tecnologia da Informação')
                    }}>
                        Tecnologia da Infomação
                    </li>
                    <li onClick={() => {
                        setArea('psicologia')
                        setOptionsTitle('Psicologia')
                    }}>
                        Psicologia
                    </li>
                    <li onClick={() => {
                        setArea('pedagogia')
                        setOptionsTitle('Pedagogia')
                    }}>
                        Pedagogia
                    </li>
                </ul>}
            </div> */}
            <section className='associate-search'>
                {ourAssociates.map(associate => 
                    <AssociateCard 
                        nome={associate.nome}
                        titulo={associate.titulo}
                        lattes={associate.lattes}
                        area={associate.area}
                        atuacao={associate.atuacao}
                        email={associate.email}
                        graduacoes={associate.graduacoes}
                        especializacoes={associate.especializacoes}
                        mestrados={associate.mestrados}
                        doutorados={associate.doutorados}
                        image={associate.image}
                    />
                )}
            </section>
        </>
    )
}

export default AssociateSearch;