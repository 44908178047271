import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import './styles.css';
import emailjs from '@emailjs/browser';

const AssociateForm = () => {

    const [register, setRegister] = useState({})
    const form = useRef();

    const handleChange = ({ target: { name, value } }) => {
        setRegister(({ ...register, [name]: value }))
    }

    const registerSubmit = (event) => {
        event.preventDefault();

        emailjs.sendForm('gmailMessage', 'template_zu90fur', form.current, 'user_9kJbpTkkmqwE1KOwv0AGa')
            .then((result) => {
                alert("Formulário preenchido com sucesso, a equipe do ITAI entrará em contato para formalização da associação.")
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <form className="box-form" ref={form} onSubmit={registerSubmit}>
            <h3>Após o cadastro, a equipe do ITAI entrará em contato para formalização da associação.</h3>
            <TextField className="input-form" fullWidth required variant="outlined" label="Nome" onChange={handleChange} name="nome"></TextField>
            <TextField className="input-form" fullWidth required variant="outlined" label="E-mail" onChange={handleChange} name="email"></TextField>
            <TextField className="input-form" fullWidth required variant="outlined" label="Telefone" onChange={handleChange} name="telefone"></TextField>
            <TextField className="input-form" fullWidth required variant="outlined" label="Endereço" onChange={handleChange} name="endereco"></TextField>
            <div className="instructions">
                <TextField className="input-form" fullWidth required variant="outlined" label="Área de Atuação" placeholder="área de atuação principal" onChange={handleChange} name="area"></TextField>
            </div>
            <div className="instructions">
                <TextField className="input-form input-form-link" placeholder="Inserir link do Lattes ou anexar currículo profissional" fullWidth variant="outlined" label="Link do Currículo" onChange={handleChange} name="curriculo"></TextField>
            </div>
            <Button className="button-form" fullWidth type="submit" color="primary">Cadastrar</Button>
        </form>
    );
}

export default AssociateForm;