import './TestemonialCard.css';

function TestemonialCard({name, statement, img}) {
    return (
        <div className='testemonial-card'>
            <h1>"</h1>
            <p>{ statement }</p>
            <h3>{ name }</h3>
            <img src={img} alt='' />
        </div>
    );
}

export default TestemonialCard;