import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

function Button(props) {
	const url = props.url;
	return (
		<div>
			{url ? (
				<Link to={props.url}>
					<button className={props.type}>{props.title}</button>
				</Link>
			) : (
					<button className={props.type}>{props.title}</button>
				)}
		</div>
	);
}

export default Button;
