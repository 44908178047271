import LogoCard from '../LogoCard/LogoCard';
import './LogosHolder.css';

function LogosHolder({ title, array }) {
    return (
        <div className='logos-holder'>
            <h2>{ title }</h2>
            <div className='cards'>
                {array.map((company) => 
                    <LogoCard 
                        title={company.title}
                        text={company.text}
                        logo={company.image ? company.image : company.default}
                    />
                )}
            </div>
        </div>
    )
}

export default LogosHolder;