import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import solutionsBanner from '../../assets/banners/solutions.png';
import SolutionCard from '../../components/SolutionCard/SolutionCard';
import {solutions} from '../../data/solutions';
import './styles.css';

function index() {
    return (
        <PageDefault>
            <SlideDefault 
                h2='Oferecemos soluções personalizadas para sua empresa ou projeto'
                text='O ITAI se diferencia na execução de seus projetos pela capacidade de articulação em torno da sua rede de pesquisadores, gerando um ecossistema de inovação e sinergia de conhecimentos.'
                image={solutionsBanner}
            />

            <div className="solutions-holder">
                {solutions.map((solution) => 
                    <SolutionCard 
                        {...solution}
                    />
                )}
            </div>

        </PageDefault>
    )
}

export default index;