import { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import emailjs from '@emailjs/browser';
import './InternForm.css'

function InternForm() {
    const [values, setValues] = useState({});
    const form = useRef();

    const handleChange = ({ target: { name, value } }) => {
        setValues(({ ...values, [name]: value }))
    }

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_vxfx0bj', 'template_hh6ab17', form.current, 'xLo7uU70_lFfZfzMZ')
            .then((result) => {
                alert("Formulário preenchido com sucesso, a equipe do ITAI entrará em contato.");
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset();
    };

    return (
        <form className='intern-form' ref={form} onSubmit={sendEmail}>
            <TextField
                placeholder="insira seu nome"
                required
                label="Nome"
                name="name"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira seu email no formato seuemail@provedor.com"
                required
                label="Email"
                name="email"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira seu telefone no formato 45 999999999"
                required
                label="Telefone"
                name="phone"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira seu endereço"
                required
                label="Endereço"
                name="address"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira seu curso"
                required
                label="Curso"
                name="course"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira o ano ou periodo de seu curso"
                required
                label="Ano/Periodo"
                name="courseYear"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira seu turno (manhã tarde ou noite)"
                required
                label="Turno das aulas"
                name="classSchedule"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <TextField
                placeholder="insira sua Instituição de Ensino"
                required
                label="Instituição de Ensino"
                name="institution"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                className='form-content'
            />
            <Button className='form-content button-form' type="submit" color="primary">Cadastrar</Button>
        </form>
    )
}

export default InternForm;