import arrowRight from '../../assets/arrowRight2.png';
import './NextArrow.css';

function NextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div className={className} style={{ ...style, display: 'block', margin: '-10px auto', }} onClick={onClick}>
			<img src={arrowRight} alt="Ir para a direita" style={{maxWidth: 'none'}} />
		</div>
	);
}

export default NextArrow;