import React from 'react'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import PageDefault from '../../components/PageDefault';
import ExtendProjectForm from '../../components/ProjectForm/ExtendProjectForm';
import SlideDefault from '../../components/SlideDefault';

import projectIMG from  '../../assets/banners/projects.png'

import './styles.css';

function Projects() {
    return (
        <>
            <PageDefault>
                <ScrollToTopOnMount />
                <div className="allContainer">
                    <div className="header">
                        <SlideDefault
                            h2="Banco de Projetos"
                            textTwo="O ITAI possui mais de 20 atuações em desenvolvimento de projetos e pesquisas, com experiências em convênios e parcerias com grandes instituições e empresas.
                            Nesse intuito de fortalecer o ecossistema de inovação, o ITAI está lançando o Banco de Projetos, uma iniciativa que visa estimular o cadastro de projetos e iniciativas de pesquisas que desenvolverão ainda mais a região."
                            buttonRenderization={false}
                            image={projectIMG}
                        />
                    </div>
                    <div className='header-text'>
                        <h3>Cadastre seu projeto no ITAI e garanta uma oportunidade de publicar, ampliar e desenvolver seu projeto.</h3>
                    </div>
                    <div>
                        <ExtendProjectForm />
                    </div>
                </div>
            </PageDefault>
        </>
    )
}

export default Projects;