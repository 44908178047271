import React from 'react';
import './styles.css'

const ListComponent = ({ arrayList, nameInput, text }) => {
    return (
        <div className="container">
            <ul>
                {arrayList.map((arrayList) =>
                    <li className="margin">
                        <div>{arrayList[nameInput]}</div>
                        <h3>{arrayList[text]}</h3>
                    </li>
                )}
            </ul>
        </div>
    );
}

export default ListComponent;