import './NewsCard.css';

function NewsCard({image, title, excerpt, link}) {
    return (
        <a className="news-card" href={link} target='_blank' rel='noreferrer'>
            <img src={ image } alt='Imagem da notícia' />
            <h3>{ title }</h3>
            <p>{ excerpt }</p>
        </a>
    )
}

export default NewsCard;