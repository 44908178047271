import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './reset.css';

import LandingPage from './pages/LandingPage';
import About from './pages/About';
import Transparency from './pages/Transparency';
import History from './pages/History';
import Expertises from './pages/Expertises';
import Contact from './pages/Contact';
import Companies from './pages/Companies';
import Projects from './pages/Projects';
import Associate from './pages/Associate';
import Campaign from './pages/Campaign';
import Internship from './pages/Internship';
import IES from './pages/IES/IES';
import InstSec from './pages/InstSec/InstSec';
import Cases from './pages/Cases';
import Solutions from './pages/Solutions';
import GenericSolutionPage from './pages/GenericSolutionPage/GenericSolutionPage';
import GenericCoursePage from './pages/GenericCoursePage/GenericCoursePage';
import EventPage from './pages/Eventspage';
import VagasPage from './pages/Vagaspage';
import EventsLandingPage from './pages/EventsLandingPage';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f7c601' },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/about" component={About} />
          <Route path="/expertises" component={Expertises} />
          <Route path="/history" component={History} />
          <Route path="/transparency" component={Transparency} />
          <Route path="/contact" component={Contact} />
          <Route path="/companies" component={Companies} />
          <Route path="/projects" component={Projects} />
          <Route path="/campaign" component={Campaign} />
          <Route path="/associates" component={Associate} />
          <Route path="/internship" component={Internship} />
          <Route path="/ies" component={IES} />
          <Route path="/inst-sec" component={InstSec} />
          <Route path="/cases" component={Cases} />
          <Route path="/solutions" component={Solutions} />
          <Route path="/solution/:id" component={GenericSolutionPage} />
          <Route path="/evento" component={EventPage} />
          <Route path="/trabalhe-conosco" component={VagasPage} />
          <Route path="/course/:id" component={GenericCoursePage} />
          <Route path="/evento/:id" component={EventsLandingPage} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
