import React from 'react';
import { importAll } from '../../util/importAll';
import Slider from 'react-slick';
import NextArrow from '../NextArrow/NextArrow';
import PrevArrow from '../PrevArrow/PrevArrow';
import './styles.css';

function Clients() {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		slidesToShow: 4,
		slidesToScroll: 4,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1
				}
			}
		]
	};

	const logos = importAll(require.context('../../assets/clientes', false, /\.(png|jpe?g|svg)$/));

	return (
		<div className="partnersResponsive">
			<h2>Clientes</h2>
			<Slider {...settings}>

				{logos.map(logo => <div>
					<img src={logo.default} className='partnerLogo' alt='logo do parceiro'/>
				</div>)}

			</Slider>
		</div>
	);
}

export default Clients;