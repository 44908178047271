import { Link } from 'react-router-dom';
import './SolutionCard.css';

function SolutionCard({id, icon, title, firstText}) {
    const parsedText = `${firstText.slice(0, 150)}...`;
    
    return (
        <div className="solution-card">
            {icon}
            <h3>{title}</h3>
            <p>{parsedText}</p>
            <Link to={`/solution/${id}`} ><button>Saiba mais</button></Link>
        </div>
    )
}

export default SolutionCard;