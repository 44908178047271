import './SolutionSquare.css';

function SolutionSquare({ title }) {
    return (
        <div className='solution-square'>
            <h3>{title}</h3>
            <hr />
        </div>
    )
}

export default SolutionSquare;