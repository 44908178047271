import React from 'react';
import './styles.css';
export function Square(props) {
	return (
		<figure className="normalSquare">
			<img src={props.image} alt={props.legend} />
            <figcaption className="captionDefault"><h3>{props.legend}</h3></figcaption>
            <figcaption className="captionHide">
                <h3>{props.legend}</h3>
                <p>{props.content}</p>
            </figcaption>
		</figure>
	);
}
export function BigSquare(props) {
	return (
		<figure className="bigSquare">
			<img src={props.image} alt={props.legend} />
            <figcaption className="captionBigDefault"><h3>{props.legend}</h3></figcaption>
            <figcaption className="captionBigHide">
                <h3>{props.legend}</h3>
                <p>{props.content}</p>
            </figcaption>
		</figure>
	);
}
