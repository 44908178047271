import React from 'react';
import './styles.css';

import Footer from './footer';
import BurguerMenu from './burgerMenu';
import MainMenu from './mainMenu';
import ArrowToTop from '../ArrowToTop';

import { FaLinkedinIn, FaInstagram, FaFacebookF} from 'react-icons/fa';

function PageDefault({children}) {
	return (
		<>
			<ArrowToTop />
			<header>
				<div className="head">
					<a href="https://www.linkedin.com/company/itai" rel="noreferrer" className="socialLinks" target="_blank">
						<FaLinkedinIn size={25} />
					</a>
					<a href="https://www.instagram.com/itai.org.br" rel="noreferrer" className="socialLinks" target="_blank">
						<FaInstagram size={25} />
					</a>
					<a href="https://www.facebook.com/itai.org.br" rel="noreferrer" className="socialLinks" target="_blank">
						<FaFacebookF size={25} />
					</a>
				</div>
				<div className="navigation">
					<BurguerMenu />
					<MainMenu />
				</div>
			</header>
			<main>{children}</main>
			<footer>
				<Footer />
			</footer>
		</>
	);
}

export default PageDefault;
