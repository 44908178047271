import './DirectorBox.css';
import { HiOutlineMail } from 'react-icons/hi';
import { CgFileDocument } from 'react-icons/cg';

function DirectorBox({ image, name, title, email, lattes, text }) {
    return (
        <div className="director-box-bg">
            <div className="director-box">
                <div className='top'>
                    <img src={image} alt={title} />
                    
                    <div className='infos'>
                        <h3>{ name }</h3>
                        <p className='title'>{ title }</p>
                        <p className='email'><HiOutlineMail className='icon'/>{ email }</p>
                        <a href={ lattes } target='_blank' rel='noreferrer' >
                            <CgFileDocument className='icon' />
                            Lattes
                        </a>
                    </div>
                </div>

                <p className='text'>{ text }</p>
            </div>
        </div>
    )
}

export default DirectorBox;