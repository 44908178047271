import React from 'react';
import './styles.css';
import { Square, BigSquare } from '../Square';
import { smallSquare, bigSquare } from '../../data/expertises';

function ExpertisesComponent() {
	return (
		<section className="expertiseSquare">

			{smallSquare.map((item) => {
				return <Square
					image = {item.image}
					legend = {item.legend}
					content = {item.content}
				/>
			})}

			{bigSquare.map((item) => {
				return <BigSquare
					image = {item.image}
					legend = {item.legend}
					content = {item.content}
				/>
			})}

		</section>
	);
}

export default ExpertisesComponent;