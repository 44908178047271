import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

import arrowDown from "../../assets/arrowDown.png";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import DownloadFile from "../../components/DownloadFile";

import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import PageDefault from "../../components/PageDefault";

function Transparency() {
  const [tab, setTab] = useState(0);

  return (
    <PageDefault>
      <ScrollToTopOnMount />
      <section className="transparency">
        <div className="header">
          <h2>Transparência</h2>
        </div>
        <div className="tabsResponsive">
          <div className="tabSelect">
            <span>Selecione</span>
            <img src={arrowDown} alt="Selecionar" />
          </div>
          <div className="tabsResponsiveContent">
            <Link onClick={() => setTab(0)}>Estatuto e Regimento</Link>
            <Link onClick={() => setTab(1)}>2022</Link>
            <Link onClick={() => setTab(2)}>2021</Link>
            <Link onClick={() => setTab(3)}>2020</Link>
            <Link onClick={() => setTab(4)}>2019</Link>
            <Link onClick={() => setTab(5)}>2018</Link>
          </div>
        </div>
        <Tabs selectedIndex={tab} onSelect={(index) => console.log(index)}>
          <TabList>
            <Tab onClick={() => setTab(0)}>Estatuto e Regimento</Tab>
            {/* <Tab onClick={() => setTab(1)}>2021</Tab> */}
            <Tab onClick={() => setTab(1)}>2022</Tab>
            <Tab onClick={() => setTab(2)}>2021</Tab>
            <Tab onClick={() => setTab(3)}>2020</Tab>
            <Tab onClick={() => setTab(4)}>2019</Tab>
            <Tab onClick={() => setTab(5)}>2018</Tab>
            {/* <Tab onClick={() => setTab(5)}>2017</Tab> */}
          </TabList>

          <TabPanel>
            <h3 className="titlePanel">Estatuto e Regimento</h3>
            <DownloadFile
              title="Estatuto Social ITAI"
              documentURL="/documents/estatuto2023.pdf"
            />
            <DownloadFile
              title="Regimento Interno"
              documentURL="/documents/RegimentoInterno.pdf"
            />
          </TabPanel>
          
          <TabPanel>
          <h3 className="titlePanel">2022</h3>
            <DownloadFile 
              title='Balanço patrimonial'
              documentURL='documents/2022/balançopatrimonial2022.pdf'
            />
            <DownloadFile 
              title='D.R.E'
              documentURL='documents/2022/dre2022.pdf'
            />
            <DownloadFile 
              title='DFC'
              documentURL='documents/2022/dfc2022.pdf'
            />
            <DownloadFile 
              title='Notas explicativas'
              documentURL='documents/2022/notas2022.pdf'
            />
          </TabPanel>

          <TabPanel>
          <h3 className="titlePanel">2021</h3>
            <DownloadFile 
              title='Balanço patrimonial'
              documentURL='documents/2021/balancopatrimonial.pdf'
            />
            <DownloadFile 
              title='D.R.E'
              documentURL='documents/2021/dre.pdf'
            />
            <DownloadFile 
              title='DFC'
              documentURL='documents/2021/dfc.pdf'
            />
            <DownloadFile 
              title='Notas explicativas'
              documentURL='documents/2021/notasexplicativas.pdf'
            />
          </TabPanel>
          
          <TabPanel>
            <h3 className="titlePanel">Resultados Financeiros - 2020</h3>

            <DownloadFile
              title="Notas Explicativas 2020"
              documentURL="documents/2020/NotasExplicativas2020.pdf"
            />
            <DownloadFile
              title="DFC 2020"
              documentURL="documents/2020/DFC2020.pdf"
            />
            <DownloadFile
              title="Balanço Patrimonial 2020"
              documentURL="/documents/2020/BalancoPatrimonial2020.pdf"
            />
            <DownloadFile
              title="D.R.E 2020"
              documentURL="/documents/2020/DRE2020.pdf"
            />
          </TabPanel>
          <TabPanel>
            <h3 className="titlePanel">Resultados Financeiros - 2019</h3>
            <DownloadFile
              title="DFC 2019"
              documentURL="/documents/2019/DFC2019.pdf"
            />
            <DownloadFile
              title="Notas Explicativas 2019"
              documentURL="/documents/2019/NotasExplicativas2019.pdf"
            />
            <DownloadFile
              title="Balanço Patrimonial 2019"
              documentURL="/documents/2019/BalancoPatrimonial2019.pdf"
            />
            <DownloadFile
              title="D.R.E 2019"
              documentURL="/documents/2019/DRE2019.pdf"
            />
          </TabPanel>
          <TabPanel>
            <h3 className="titlePanel">Resultados Financeiros - 2018</h3>
            <DownloadFile
              title="DFC 2018"
              documentURL="/documents/2018/DFC2018.pdf"
            />
            <DownloadFile
              title="Notas Explicativas 2018"
              documentURL="/documents/2018/NotasExplicativas2018.pdf"
            />
            <DownloadFile
              title="Balanço Patrimonial 2018"
              documentURL="/documents/2018/BalancoPatrimonial2018.pdf"
            />
            <DownloadFile
              title="DRE 2018"
              documentURL="/documents/2018/DRE2018.pdf"
            />
          </TabPanel>
        </Tabs>
      </section>
    </PageDefault>
  );
}

export default Transparency;
