import { Link } from 'react-router-dom';
import './LandingSolutionCard.css';

function LandingSolutionCard({ id, image, title, text }) {
    const parsedText = `${text.slice(0, 100)}...`;

    return (
        <div className="landing-solution-card">
            <img src={image} alt={title} />
            <h4>{ title }</h4>
            <p>{ parsedText }</p>
            <Link to={`solution/${id}`}> Saiba mais </Link>
        </div>
    )
}

export default LandingSolutionCard;