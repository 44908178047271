import { timelineContent } from '../../data/TimelineContent';
import TimelineBlock from '../TimelineBlock/TimelineBlock';
import TextBanner from '../../components/TextBanner/TextBanner';
import './Timeline.css';

function Timeline() {
    return (
        <section className='timeline'>
            <TextBanner>Timeline ITAI</TextBanner>
            {timelineContent.map(item => 
                <TimelineBlock
                    id={item.id}
                    year={item.year}
                    content={item.content}
                    image={item.image}
                />
            )}
            <p className='timeline-rights'>Fotos retiradas de <a href='https://br.freepik.com/' target='_blank' rel='noreferrer' className='freepik-link'>Freepik</a></p>
        </section>
    );
}

export default Timeline;