import { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../TabPanel/TabPanel';
import ListComponent from '../ListComponent';
import AssociateForm from '../AssociateForm';
import ImageDisplay from '../ImageDisplay/ImageDisplay';
import { associateAdvantages, associateAction } from '../../data/associateData';
import './styles.css'

// Images:
import reuniao from '../../assets/associatesDisplay/reuniao.png';
import reuniao02 from '../../assets/associatesDisplay/reuniao02.png';
import apresentacao01 from '../../assets/associatesDisplay/apresentacao01.png';
import apresentacao02 from '../../assets/associatesDisplay/apresentacao02.png';
import diretores from '../../assets/associatesDisplay/diretores.png';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const AssociateSwitch = () => {
    const [value, setValue] = useState(0);
    const images = [reuniao, apresentacao01, reuniao02, apresentacao02, diretores];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='root' /* className="test" */>
            <div className='vertical-tabs'>
                <Tabs
                    orientation="horizontal"
                    value={value}
                    onChange={handleChange}
                    className='tabs'
                    indicatorColor="primary"
                >
                    <Tab label="PERFIL DO ASSOCIADO" {...a11yProps(0)} className='tab'/>
                    <Tab label="BENEFÍCIOS" {...a11yProps(1)} className='tab'/>
                    <Tab label="ATUAÇÃO DO ASSOCIADO" {...a11yProps(2)} className='tab'/>
                    <Tab label="CADASTRO" {...a11yProps(3)} className='tab'/>
                </Tabs>
            </div>

            <TabPanel value={value} index={0} className='tab-panel'>
                <div>
                    <ImageDisplay
                        images={images}
                        title='Quem são os
                        Associados Profissionais'
                        underline={false}
                        text='Pessoas físicas, na qualidade de profissionais liberais especialistas em determinadas áreas de atuação profissional, de pesquisa e ensino, ligados ou não a instituições de ensino.'
                        btn={false}
                    />
                </div>
            </TabPanel>
            
            <TabPanel value={value} index={1} className='tab-panel'>
                <ListComponent arrayList={associateAdvantages} nameInput="container" text="text" />
            </TabPanel>

            <TabPanel value={value} index={2} className='tab-panel'>
                <ListComponent arrayList={associateAction} nameInput="container" text="text" />
            </TabPanel>

            <TabPanel value={value} index={3} className='tab-panel'>
                <AssociateForm />
            </TabPanel>
        </div>
    );
}

export default AssociateSwitch