import { Link } from 'react-router-dom';
import './TextBox.css';

function TextBox(props) {
    const isExternalLink = props.linkTo && (props.linkTo.startsWith('http://') || props.linkTo.startsWith('https://'));

    return (
        <div className='text-box'>
            <h2 className='text-box-title'>{props.title}</h2>
            {props.underline ? <hr className='text-box-line' /> : null}
            <p className='text-box-text'>{props.children}</p>
            {props.rights && props.rightsLink && (
                <a href={props.rightsLink} className='text-box-rights' target='_blank' rel='noopener noreferrer'>
                    {props.rights}
                </a>
            )}

            {props.btn && props.linkTo ? (
                isExternalLink ? (
                    <a href={props.linkTo} target='_blank' rel='noopener noreferrer'>
                        <button className='text-box-button'>Inscreva-se</button>
                    </a>
                ) : (
                    <Link to={props.linkTo}>
                        <button className='text-box-button'>Saiba mais</button>
                    </Link>
                )
            ) : null}
        </div>
    );
}

export default TextBox;
