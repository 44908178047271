import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { MdKeyboardArrowDown } from 'react-icons/md';
import LogoDIMG from '../../assets/LogoD.png';
import LogoIMG from '../../assets/logo.png';
import './styles.css';

const BurguerMenu = () => {
    const [projectsOpen, setProjectsOpen] = useState(false);
    const [whoOpen, setWhoOpen] = useState(false);
    const [partnersOpen, setPartnersOpen] = useState(false);

    return (
        <>
            <Link className="logoMenu" to="/">
                <img src={LogoIMG} alt="ITAI" />
            </Link>
            <Menu isOpen={false}>
                <Link className="logoMenu" to="/"><img src={LogoDIMG} alt="ITAI" /></Link>
                <Link className="menu-item" to="/">Início</Link>

                <Link to="#" onClick={() => setWhoOpen(!whoOpen)}>
                    Quem somos
                    <MdKeyboardArrowDown />
                </Link>
                {whoOpen &&
                    <div className="dropdown-item">
                        <ul >
                            <li>
                                <Link className="menu-item" to="/about">Sobre</Link>
                            </li>
                            <li>
                                <Link className="menu-item" to="/expertises">Áreas de Atuação</Link>
                            </li>
                            <li>
                                <Link className="menu-item" to="/history">Histórico</Link>
                            </li>
                            <li>
                                <Link className="menu-item" to="/transparency">Transparência</Link>
                            </li>
                        </ul>
                    </div>
                }

                <Link className="menu-item" to="/solutions">Soluções</Link>

                <Link to="#" onClick={() => setProjectsOpen(!projectsOpen)}>
                    Projetos e Ações
                    <MdKeyboardArrowDown />
                </Link>
                {projectsOpen &&
                    <div className="dropdown-item">
                        <ul >
                            <li><Link to="/projects">Cadastro de Projetos</Link></li>
                            <li><Link to="/cases">Projetos Desenvolvidos</Link></li>
                        </ul>
                    </div>
                }

                <Link to="#" onClick={() => setPartnersOpen(!partnersOpen)}>
                    Parceiros
                    <MdKeyboardArrowDown />
                </Link>
                {partnersOpen &&
                    <div className="dropdown-item">
                        <ul >
                            <li>
                                <Link className="menu-item" to="/associates">Associados</Link>
                            </li>
                            <li>
                                <Link className="menu-item" to="/companies">Empresas</Link>
                            </li>
                            <li>
                                <Link className="menu-item" to="/ies">IES</Link>
                            </li>
                            <li>
                                <Link className="menu-item" to="/inst-sec">Organizações Representativas</Link>
                            </li>
                        </ul>
                    </div>
                }
                <Link className="menu-item" to="/internship">Estágios</Link>
                <a href='https://www.itai.org.br/editora' target='_blank' rel='noreferrer' className="menu-item">Editora</a>
                <Link className="menu-item" to="/trabalhe-conosco">Trabalhe conosco</Link>
                <Link className="menu-item" to="/evento">Eventos</Link>
                <Link className="menu-item" to="/contact">Contato</Link>
            </Menu>
        </>
    );
}


export default BurguerMenu;