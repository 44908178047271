import { BiBullseye } from 'react-icons/bi';
import { FaChartBar } from 'react-icons/fa';
import { AiOutlineStar } from 'react-icons/ai'

export const mvvText = [
    {
        icon: <BiBullseye className='elastic-icon'/> ,
        title: "MISSÃO",
        content : "Promover o ensino, a pesquisa e desenvolver soluções inovadoras por meio da integração e mobilização dos atores do Ecossistema de Inovação visando o desenvolvimento científico, tecnológico e econômico sustentáveis. ",
        bgColor: '#00446A'
    },
    {
        icon: <FaChartBar className='elastic-icon'/> ,
        title : "VISÃO",
        content : "Ser reconhecido como uma Instituição que cria e viabiliza projetos de soluções científicas, tecnológicas e de inovação em suas áreas de atuação.",
        bgColor: '#003F61'
    },
    {
        icon: <AiOutlineStar className='elastic-icon'/> ,
        title : "VALORES",
        content : 
            <ul>
                <li>Compromisso ético, responsabilidade social e respeito à diversidade;</li> 
                <li>Direcionamento aos preceitos de sustentabilidade (ambiental, econômica, social);</li> 
                <li>Comprometimento com as ações pactuadas;</li> 
                <li>Agilidade, flexibilidade e simplificação dos processos;</li> 
                <li>Atuação com transparência;</li> 
                <li>Busca contínua pelo conhecimento;</li> 
                <li>Valorização e respeito à equipe e parceiros.</li>
            </ul> ,
        bgColor: '#003756'
    }
]
