import { useParams } from 'react-router-dom';
import events from '../../data/events'; // Supondo que seus eventos estejam em um arquivo separado
import EventsModal from '../../components/EventsModal';
import './styles.css';

function EventsLandingPage() {
    const { id } = useParams();
    const event = events.find(event => event.id === id);

    if (!event) {
        return <div>Evento não encontrado</div>;
    }

    return (
        <div className="events-landing-page">
            <EventsModal {...event} setOpen={() => { }} />
        </div>
    );
}

export default EventsLandingPage;
