import { useParams } from 'react-router-dom';
import { solutions } from '../../data/solutions';
import { BiTimeFive } from 'react-icons/bi';
import { AiOutlineCalendar } from 'react-icons/ai';
import { GiBlackBook } from 'react-icons/gi';
import { BsPeople } from 'react-icons/bs';
import PageDefault from '../../components/PageDefault';
import SlideDefault from '../../components/SlideDefault';
import ScroolToTopOnMount from '../../components/ScrollToTopOnMount';
import './GenericCoursePage.css';

function GenericCoursePage() {
    const { id } = useParams();
    const course = solutions[2].courses.find(item => item.id === Number(id));

    return (
        <PageDefault>
            <ScroolToTopOnMount />
            <SlideDefault 
                h2={course.title}
                image={course.image}
            />

            <div className='course-infos'>
                <div className="left">
                    <p>
                        <GiBlackBook className='icon' />
                        Formato: { course.format }
                    </p>
                    <p>
                        <BiTimeFive className='icon' /> 
                        Duração: { course.duration }
                    </p>
                    <p>
                        <AiOutlineCalendar className='icon' /> 
                        Conclusão: { course.conclusion }
                    </p>
                    <p>
                        <BsPeople className='icon' />
                        Vagas por turma: { course.students }
                    </p>
                </div>
                <div className="right">
                    <h3>Objetivo do curso</h3>
                    <p>{ course.objective }</p>
                </div>
            </div>

            <div className="topics">
                <h3>Ementa</h3>
                {course.ementa}
                <p>Coordenação técnica e executiva: { course.coordination }</p>
            </div>

        </PageDefault>
    )
}

export default GenericCoursePage;